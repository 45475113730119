import React from 'react'
import Header from '../components/Header'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'

const StyledDiv = styled.div`
    width: 1000px;
    max-width: 100vw;
    padding: 20px;
    padding-bottom: 100px;
`

const StyledContent = styled.p`
    margin: 0;
    padding: .5rem;
`

export default function PrivacyPolicy(props) {
    return(
        <>
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                <meta property="og:title" content="Privacy Policy" />
                <meta
                property="og:description"
                content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                />
            </Helmet>
            <Header title='Privacy Policy' />
            <StyledDiv>
                {props.value.split('\n').map((line, index) => (
                    <StyledContent key={index}>{line}</StyledContent>
                ))}
            </StyledDiv>
        </>
    )
}