import NavbarList from './NavbarList'
import PillButton from './PillButton'
import styled, { withTheme } from "styled-components";
import React, {Fragment, useState} from 'react'
import { Link } from 'react-router-dom';
import {useSpring, animated} from 'react-spring'
import useMeasure from 'react-use-measure'

import { device } from '../models/device';
import { theme } from '../models/theme'
import logo from '../images/landing/navigation/pinscreen_logo.png';
// import { ReactComponent as Logo } from '../images/landing/navigation/pinscreen_logo_2_combined.svg';
import menu from '../images/landing/navigation/menu.svg';
import { useRef } from 'react';


const StyledInnerList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: auto;
    height: 4rem;
    gap: ${props => props.theme.largeSpace};
    a {
        text-align: center;
        width: 100%;
    }

    @media ${device.tablet} {
        gap: 24px;
    }

    @media ${device.mobile} {
        width: 100%;
        height: auto;
        flex-direction: column;
        gap: 6px;
    }
`;

const StyledListElement = styled.div`
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: center;
    @media ${device.mobile} {
        width: 100%;
        height: auto;
        flex-direction: column;
    }
`

const StyledOuterList = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 24px 0 24px;
    height: 100%;
    background: none;
    box-sizing: border-box;
    @media ${device.mobile} {
        padding: 0 0 0 24px;
        justify-content: space-between;
    }
`;

const StyledLogoListItem = styled(animated.div)`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    a {
        color: ${props => props.theme.textLight};
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        h2 {
            color: ${props => props.theme.textLight};
            white-space: nowrap;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
        }
    }
`;

const StyledVertialBox = styled.div`
    height: 1.25rem;
    display: flex;
    flex-direction: column;
    line-height: 0;
    justify-content: center;
`

const StyledCenterNavListItem = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10%;
    @media ${device.mobile} {
        left: 0;
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
        background: ${props => props.theme.panelDark};
    }
`;

const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 192px;
    height: auto;
    text-align: center;
`;

const StyledLogoImage = styled.img`
    display: block;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    padding-bottom: 0.2rem;

    @media ${device.mobile} {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        margin-left: -1rem;
        padding-bottom: 0.2rem;
    }
`;

const StyledMenuButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    text-align: center;
    border-radius: 0;
    background: none;
    img {
        display: block;
        width: 24px;
        height: 24px;
        padding: 12px;
    }
    @media ${device.mobile} {
        background: ${props => props.theme.panelDark};

        :hover, :active {
            img {
                filter: brightness(2);
            }
        }
    }
`;

const StyledNavbarDiv = styled.div`
    width: 100vw;
    position: fixed;
    overflow: hidden;
    top: 0;
    height: 86px;
    z-index: 100000;
    @media ${device.mobile} {
    
    }
`;

const StyledInnerNavbarDiv = styled.div`
    display: flex;
    width: ${props => props.parentWidth + 'px'};
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.panelDark};
    position: fixed;
    z-index: 20;
    top: 0;
    height: 86px;
`;

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

const StyledListButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 192px;
    background: none;
    cursor: pointer;
    
    @media ${device.tablet} {
        padding: 0;
    }

    @media ${device.mobile} {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        justify-content: center;
        align-items: center;
    }

    text-align: center;
    color: ${props => props.theme.textLight};
    text-decoration: none;
    font-size: 1.1rem;
    white-space: nowrap;
    :hover {
        color: ${props => props.theme.accent};
        background: none;
    }
`;

const VerticalP = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    h2 {
        color: ${props => props.theme.textLight};
        font-family: 'Poppins', sans-serif;
        white-space: nowrap;
    }
`;

const HorizontalP = styled.div`
    display: flex;
    flex-direction: row;
    height: auto;
    margin: 0;
    margin-block: 0;
`;

const NoMarginP = styled.p`
    margin: 0;
    margin-block: 0;
`;

const NoMarginH2 = styled.h2`
    margin: 0;
    margin-block: 0;
`;

// const StyledLogo = styled(Logo)`
//     width: auto;
//     height: 1.5rem;
// `

function mobileContent(animatedMenuStyle, mobileMenuRef, mobileMenuOpen, setMobileMenuOpen, animatedRef, setTitleIsHovered, titleHoverAnimStyle) {
    return (
       <Fragment>
            <StyledLogoListItem onClick={() => {setMobileMenuOpen(false); console.log(mobileMenuOpen)}} style={titleHoverAnimStyle} onMouseEnter={ () => {setTitleIsHovered(true);}} onMouseLeave={ () => {setTitleIsHovered(false);}}>
                <Link to="/">
                    <StyledLogoImage src={logo}/>
                    <VerticalP>
                        <h2>PINSCREEN</h2>
                    </VerticalP>
                </Link>
            </StyledLogoListItem>
            <StyledLogoListItem>

                <StyledMenuButton ref={mobileMenuRef} onClick={() => {setMobileMenuOpen(!mobileMenuOpen); console.log(mobileMenuOpen)}}>
                    <img src={menu}/>
                </StyledMenuButton>
                <animated.div style={animatedMenuStyle}>
                <StyledCenterNavListItem ref={animatedRef}>
                    <StyledInnerList>
                        <StyledListElement>
                            <NavbarList title="AI VFX" contents={[["Services", "/vfx-services"], ["Projects", "/vfx-projects"], ["Technology", "/vfx-technology"], ["Research", "/vfx-research"]]} onClickedSubMenu={() => {setMobileMenuOpen(!mobileMenuOpen); console.log(mobileMenuOpen)}}/>
                        </StyledListElement>
                        <StyledListElement>
                            <NavbarList title="AVATAR NEO" contents={[["Creator App", "/avatar-neo-creator-app"], ["Developer SDK", "/avatar-neo-developer-sdk"]]} onClickedSubMenu={() => {setMobileMenuOpen(!mobileMenuOpen); console.log(mobileMenuOpen)}}/>
                        </StyledListElement>
                        <StyledListElement>
                            <StyledDiv>
                                <Link to={'/jobs'}>
                                        <StyledListButton onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}>JOBS</StyledListButton>
                                </Link>
                            </StyledDiv>
                        </StyledListElement>
                        <StyledListElement>
                            <NavbarList title="ABOUT US" contents={[["Company", "/about-us-company"], ["Ethics & Safety", "/ethics-safety"], ["Press", "/about-us-press"]]} onClickedSubMenu={() => {setMobileMenuOpen(!mobileMenuOpen); console.log(mobileMenuOpen)}}/>
                        </StyledListElement>
                        <StyledListElement>
                            <StyledDiv>
                                <Link to={'/contact'}>
                                        <StyledListButton onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}>CONTACT</StyledListButton>
                                </Link>
                            </StyledDiv>
                        </StyledListElement>
                    </StyledInnerList>
                </StyledCenterNavListItem>
                </animated.div>
            </StyledLogoListItem>
       </Fragment>
    )
   }

function desktopContent(setTitleIsHovered, titleHoverAnimStyle) {
 return (
    <Fragment>
        <StyledCenterNavListItem>
            <StyledLogoListItem style={titleHoverAnimStyle} onMouseEnter={ () => {setTitleIsHovered(true);}} onMouseLeave={ () => {setTitleIsHovered(false);}}>
                <Link to="/">
                    <StyledLogoImage src={logo}/>
                    <h2>PINSCREEN</h2>
                </Link>
            </StyledLogoListItem>
            <StyledInnerList>
                <StyledListElement>
                    <NavbarList title="AI VFX" contents={[["Services", "/vfx-services"], ["Projects", "/vfx-projects"], ["Technology", "/vfx-technology"], ["Research", "/vfx-research"]]}/>
                </StyledListElement>
                <StyledListElement>
                    <NavbarList title="AVATAR NEO" contents={[["Creator App", "/avatar-neo-creator-app"], ["Developer SDK", "/avatar-neo-developer-sdk"]]}/>
                </StyledListElement>
                <StyledListElement>
                    <StyledDiv>
                        <Link to={'/jobs'}>
                                <StyledListButton>JOBS</StyledListButton>
                        </Link>
                    </StyledDiv>
                </StyledListElement>
                <StyledListElement>
                    <NavbarList title="ABOUT US" contents={[["Company", "/about-us-company"], ["Ethics & Safety", "/ethics-safety"], ["Press", "/about-us-press"]]}/>
                </StyledListElement>
                <StyledListElement>
                    <StyledDiv>
                        <Link to={'/contact'}>
                                <StyledListButton>CONTACT</StyledListButton>
                        </Link>
                    </StyledDiv>
                </StyledListElement>
            </StyledInnerList>
        </StyledCenterNavListItem>
    </Fragment>

 )
}

export default function Navbar() {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)
    const [mobileMenuRef, mobileMenuBounds] = useMeasure()
    const [parentRef, parentBounds] = useMeasure()
    const animatedRef = useRef()
    
    React.useEffect(() => {
        function handleResize() {
            //console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setDimensions({
              height: window.innerHeight,
              width: window.innerWidth
            });
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const mobileMenuAnimatedStyle = useSpring({
        config: { duration: 180 },
        width:  '100%',
        height: (mobileMenuOpen && animatedRef ? "100vh" : "0vh"),
        opacity: (mobileMenuOpen && animatedRef ? "1" : "0"),
        overflow: 'hidden',
        top: mobileMenuBounds.bottom,
        left: 0,
        background: theme.panelDark,
        position: 'absolute',
        })

        const [titleIsHovered, setTitleIsHovered] = React.useState(false);
        const titleHoverAnimStyle = useSpring({
            config: { mass: 1, tension: 500, friction: 80 }
            });

    return(
        <StyledNavbarDiv ref={parentRef}>
            <StyledInnerNavbarDiv parentWidth={parentBounds.width}>
                <StyledOuterList>
                    { dimensions.width > parseInt(device.mobile.split(':')[1], 10) ? desktopContent(setTitleIsHovered, titleHoverAnimStyle) : mobileContent(mobileMenuAnimatedStyle, mobileMenuRef, mobileMenuOpen, setMobileMenuOpen, animatedRef, setTitleIsHovered, titleHoverAnimStyle) }
                </StyledOuterList>
            </StyledInnerNavbarDiv>
        </StyledNavbarDiv>
    )
}