import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import { device } from "../models/device";
import { theme } from "../models/theme"

export const StyledPageDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    background: ${props => props.theme.panelDark};
`

export const StyledSpacerDiv = styled.div`
    width: 100vw;
    height: 86px;
`

export const RegularContentDiv = styled.div`
    position: relative;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background: ${props => props.background};
    padding-bottom: 4rem;
`

export const RegularPaddinglessContentDiv = styled.div`
    position: relative;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background: ${props => props.background};
`

export const RegularGapContentDiv = styled.div`
    position: relative;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background: ${props => props.background};
    gap: 2rem;
    padding-bottom: 2em;
`

export const ContentSection = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: none;
`

export const FullWidthContentSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: none;
`

export const ContentSection3D = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: none;
    gap: 4rem;
`

export const FullPageContentSection = styled.div`
    width: 100%;
    height: calc(100vh - 86px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url(${props => props.background}) no-repeat center;
    background-size: cover;
    margin: 0 0 2rem 0;

    @media ${device.mobile} {
        height: auto;
    }
`

export const FullPageMarginlessContentSection = styled.div`
    overflow: visible;
    width: 100%;
    height: calc(100vh - 86px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: none;
    margin: 0;

    @media ${device.mobile} {
        height: auto;
    }
`

export const FullPageMarginlessContentSectionMobile = styled.div`
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 86px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: none;
    margin: 0;
`

export const FullPageMarginlessExpanderContentSectionMobile = styled.div`
    overflow: hidden;
    width: 100%;
    /* deranged hack tbh */
    height: calc(100vh - 86px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: none;
    margin: 0;
    @media ${device.mobile} {
        height: auto;
    }
`

export const FullPageWidthContentSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: none;
    padding: 0 0 4rem 0;
    transform: ${props => props.transform ? props.transform : 'unset'};
`

export const FullPageHeightContentSection = styled.div`
    width: 90%;
    min-height: calc(100vh - 86px);
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: none;
    @media ${device.mobile} {
        height: auto;
    }
`

export const StyleSlide = styled(SwiperSlide)`
    position: relative;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    background: ${props => props.background};
`

export const Title = styled.h1`
    color: ${props => props.theme.textLight};
    font-family: 'Gowun Batang', serif;
    font-size: 3rem;
    font-weight: 100;
    padding: 0.25rem;
    margin-block: 0;
    text-align: center;
    @media ${device.mobile} {
        font-size: 2rem;
    }
`

export const SubTitle = styled.h2`
    color: ${props => props.theme.textLight};
    font-size: 1.75rem;
    font-weight: 100;
    padding: 0.25rem 0 4rem 0;
    margin-block: 0;
    text-align: center;
    @media ${device.mobile} {
        font-size: 1.5rem;
    }
`

export const SubTitleExtraPadding = styled.h2`
    color: ${props => props.theme.textLight};
    font-size: 1.75rem;
    font-weight: 100;
    padding: 0.25rem 0 6rem 0;
    margin-block: 0;
    text-align: center;
    @media ${device.mobile} {
        font-size: 1.5rem;
    }
`

export const HorizontalFeatureSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 2rem;

    @media ${device.mobile} {
        gap: 0;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column-reverse;
    }
`

export const HorizontalFeatureSectionNoReverse = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 2rem;

    @media ${device.mobile} {
        gap: 0;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
`

export const FullHeightHorizontalFeatureSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    padding: 0 48px 0 48px;

    @media ${device.mobile} {
        flex-direction: column-reverse;
    }
`

export const CoverImage = styled.img`
    object-fit: cover;
    object-position: center;
    aspect-ratio: ${props => props.aspectRatio};
    height: auto;
    width: ${props => props.setWidth ? props.setWidth : '100%'};
    border-radius: ${props => props.rounded ? theme.borderRadius : '0'};

    margin-block-start: 1.25rem;
    margin-block-end: 1.25rem;

    @media ${device.mobile} {
        width: 100%;
    }
`

export const YouTubePlayer = styled.iframe`
    width: 100%;
    aspect-ratio: ${props => props.aspectRatio};
    height: auto;
    outline: none;
    border-radius: ${props => props.theme.borderRadius};
    border-style: none;
    pointer-events: fill;
`

export const LogoSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${props => props.logoGap ? props.logoGap : '2rem'};
    padding: 2rem 0 2rem 0;
`

export const LogoSectionMobileVertical = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${props => props.logoGap ? props.logoGap : '2rem'};
    padding: 2rem 0 2rem 0;
    @media ${device.mobile} {
        padding: 0 0 2rem 0;
        gap: 0;
    }
`

export const GradientCover = styled.div`
    width: 100%;
    height: ${props => props.setHeight ? props.setHeight : '100%'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, ${props => props.theme.panelDark} 0%, rgba(0, 0, 0, 0) ${props => props.percentCoverage ? props.percentCoverage : '15%'});
`

export const GradientCoverTopBottom = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, ${props => props.theme.panelDark} 0%, rgba(0, 0, 0, 0) ${props => props.percentCoverage ? props.percentCoverage : '15%'},rgba(0, 0, 0, 0) calc(100% - ${props => props.percentCoverage ? props.percentCoverage : '15%'}), ${props => props.theme.panelDark} 100%);
`

export const GradientCoverTopBottomAbsolute = styled.div`
    position: absolute;
    top: -1px;
    left: 0;
    bottom: -1px;
    right: 0;
    background: linear-gradient(0deg, ${props => props.theme.panelDark} 0%, rgba(0, 0, 0, 0) ${props => props.percentCoverage ? props.percentCoverage : '15%'},rgba(0, 0, 0, 0) calc(100% - ${props => props.percentCoverage ? props.percentCoverage : '15%'}), ${props => props.theme.panelDark} 100%);
    /* &::after {
        position: relative;
        content: ' ';
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${props => props.theme.panelDark};
        background: red;
    } */
`

// Forum Styles

export const UnderlineInput = styled.input`
    width: 100%;
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.textLight};
    background: none;
    border-radius: 0;
    border-image: unset;
    border-color: ${props => props.theme.textLight};
    border-style: solid;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid;
    text-decoration: none;
    outline-style: none;

    color-scheme: dark;

    font-size: 1.25rem;
    font-weight: 100;

    ::placeholder {
        color: ${props => props.theme.textLight};
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: ${props => props.theme.textLight};
        opacity: 1;
    }

    :hover, :active {
        outline-style: none;
        border-radius: 0;
    }

`;

export const UnderlineSelect = styled.select`
    border-radius: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.textLight};
    background: none;
    border-image: unset;
    border-color: ${props => props.theme.textLight};
    border-style: solid;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid;
    text-decoration: none;
    outline-style: none;

    color-scheme: dark;

    font-size: 1.25rem;
    font-weight: 100;

    ::placeholder {
        color: ${props => props.theme.textLight};
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: ${props => props.theme.textLight};
        opacity: 1;
    }

    :hover, :active {
        outline-style: none;
        border-radius: 0;


    }

`;

export const UnderlineSelectOption = styled.option`
    color: ${props => props.theme.textLight};
    background: ${props => props.theme.panelDark};
    font-size: 1.25rem;
    font-weight: 100;
    border-radius: 0;
`;

export const WhiteOutlineTextArea = styled.textarea`
    width: 100%;
    height: 100%;
    min-height: 15rem;
    max-height: 25rem;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.textLight};
    background: none;
    border-image: unset;
    border: ${props => props.theme.textLight};
    border-style: solid;
    border-bottom: 2px solid;
    text-decoration: none;
    outline-style: none;

    backdrop-filter: blur(10px);

    color-scheme: dark;

    font-size: 1.25rem;
    font-weight: 100;

    ::placeholder {
        color: ${props => props.theme.textLight};
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: ${props => props.theme.textLight};
        opacity: 1;
    }

    :hover, :active {
        outline-style: none;
        border-radius: 0;
    }
`;