

import styled from "styled-components";

export const ImageContainer = styled.div`
    display:flex;
    flex-direction:column;
    position: relative;
    height: 100%;
    width: 100%;
`

export const BackgroundImage = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    height: 100%;
    width: 100%;
    filter: brightness(${props => props.brightness ? props.brightness : '1'});
`

export const ContentOverContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

export const Contents = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export default function ImageBackgroundDiv(props){

    return (
    <ImageContainer>
        <BackgroundImage src={props.source} aspectRatio={props.aspectRatio} maxHeightLimit={props.maxHeightLimit} brightness={props.brightness}/>
        <ContentOverContainer>
            <Contents>
                {props.children}
            </Contents>
        </ContentOverContainer>
    </ImageContainer >
  );
}
