import styled, { css } from "styled-components";
import React, {useState, useEffect} from 'react'
import { device } from '../models/device';
import { useSpring, animated } from 'react-spring';

export const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: none;
    border-radius: none;

    color: ${props => props.textColor};
    font-size: 1.25rem;
    text-align: center;
    cursor: pointer;
    :hover {
        background: none;
    }
    transition: all ease 0.2s;

    @media ${device.mobile} {
        font-size: ${props => props.mobileShrink ? '0.75rem' : '1.25rem'};
    }
`;

export const StyledIcon = styled.div.attrs(props => ({
    className: props.Icon || 'img',
  }))`

`;

function buttonContent(props, setIsHovered) {
    return(
        <StyledButton type="button" textColor={props.textColor} mobileShrink={props.mobileShrink} light={props.light} value={props.value} onChange={props.handleChange} onClick={props.handleClick} onMouseEnter={ () => {setIsHovered(true);}} onMouseLeave={ () => {setIsHovered(false);}}>
        {props.children}
        </StyledButton>
    );
}

export default function TextButton(props) {
    const [isHovered, setIsHovered] = React.useState(false);
    const hoverAnimStyle = useSpring({
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        config: { mass: 1, tension: 500, friction: 80 }
        });
    useEffect(() => {
        // button animation on hover using react spring
        
      }, []);
    return(
        <animated.div style={hoverAnimStyle}>
            {buttonContent(props, setIsHovered)}
        </animated.div>
    )
}