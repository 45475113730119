import thumb1 from '../images/research/thumbnail_1_voodoo3d.jpg'
import thumb2 from '../images/research/thumbnail_2_xmempp.jpg'
import thumb3 from '../images/research/thumbnail_3_learn2listen.jpg'
import thumb4 from '../images/research/thumbnail_4_normalizedAvatar.jpg'
import thumb5 from '../images/research/thumbnail_5_deepfaceFaceNorm.jpg'
import thumb6 from '../images/research/thumbnail_6_paGAN.jpg'
import thumb7 from '../images/research/thumbnail_7_avatarDigitization.jpg'
import thumb8 from '../images/research/thumbnail_8_flame.jpg'
import thumb9 from '../images/research/thumbnail_9_realisticDynamicFacial.jpg'
import thumb10 from '../images/research/thumbnail_10_photorealFacialTextures.jpg'

import thumb11 from '../images/research/thumbnail_11_voodooXP.jpg'


export const Research = [
  {
    name: 'VOODOO XP: Expressive One-Shot Head Reenactment for VR Telepresence',
    authors: 'Phong Tran, Egor Zakharov, Long-Nhat Ho, Liwen Hu, Adilbek Karmanov, Aviral Agarwal, McLean Goldwhite, Ariana Bermudez Venegas, Anh Tuan Tran, Hao Li',
    publication: 'Proceedings of the 17th ACM SIGGRAPH Conference and Exhibition in Asia 2024',
    eventName: 'SIGGRAPH Asia 2024',
    links: [
      {name:'[paper]', link:'https://arxiv.org/abs/2405.16204'},
    ],
    previewPic: thumb11,
  },
  {
    name: 'VOODOO 3D: VOlumetric POrtrait Disentanglement fOr One-Shot 3D Head Reenactment',
    authors: 'Phong Tran, Egor Zakharov, Long Nhat Ho, Anh Tuan Tran, Liwen Hu, Hao Li',
    publication: 'Proceedings of the 37th IEEE International Conference on Computer Vision and Pattern Recognition 2024',
    eventName: 'CVPR 2024',
    links: [
      {name:'[paper]', link:'https://arxiv.org/pdf/2312.04651.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=Gu3oPG0_BaE'},
      {name:'[project]', link:'https://p0lyfish.github.io/voodoo3d/'}
    ],
    previewPic: thumb1,
  },
  {
    name: 'XMEM++: Production-Level Video Segmentation from Few Annotated Frames',
    authors: 'Maksym Bekuzharov, Ariana Bermudez, Joon-Young Lee, Hao Li',
    publication: 'Proceedings of the IEEE International Conference on Computer Vision 2023',
    eventName: 'ICCV 2023',
    links: [
      {name:'[paper]', link:'https://arxiv.org/pdf/2307.15958.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=3X3TUP4vKcc'},
      {name:'[project]', link:'https://max810.github.io/xmem2-project-page/'}
    ],
    previewPic: thumb2,
  },
  {
    name: 'Learning to Listen: Modeling Non-Deterministic Dyadic Facial Motion',
    authors: 'Evonne Ng, Hanbyul Joo, Liwen Hu, Hao Li, Trevor Darrell, Angjoo Kanazawa, Shiry Ginosar',
    publication: 'Proceedings of the 35th IEEE International Conference on Computer Vision and Pattern Recognition 2022',
    eventName: 'CVPR 2022',
    links: [
      {name:'[paper]', link:'https://arxiv.org/pdf/2204.08451.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=3CSlKZ5T6DU'},
      {name:'[project]', link:'https://evonneng.github.io/learning2listen/'}
    ],
    previewPic: thumb3,
  },
  {
    name: 'Normalized Avatar Synthesis Using StyleGAN and Perceptual Refinement',
    authors: 'Evonne Ng, Hanbyul Joo, Liwen Hu, Hao Li, Trevor Darrell, Angjoo Kanazawa, Shiry Ginosar',
    publication: 'Proceedings of the 34th IEEE International Conference on Computer Vision and Pattern Recognition 2021',
    eventName: 'CVPR 2021',
    links: [
      {name:'[paper]', link:'https://arxiv.org/pdf/2106.11423.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=V9r_jOiGX84'},
      {name:'[project]', link:'https://avatarneo.com/'}
    ],
    previewPic: thumb4,
  },
  {
    name: 'Deep Face Normalization',
    authors: 'Koki Nagano, Huiwen Luo Zejian Wang, Jaewoo Seo, Jun Xing, Liwen Hu, Lingyu Wei, Hao Li',
    publication: 'Proceedings of the 12th ACM SIGGRAPH Conference and Exhibition in Asia 2019',
    eventName: 'SIGGRAPH Asia 2019',
    links: [
      {name:'[paper]', link:'http://www.hao-li.com/publications/papers/siggraphAsia2019DFN.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=fzA9SBJlUDY'},
    ],
    previewPic: thumb5,
  },
  {
    name: 'PaGAN: Real-Time Avatars Using Dynamic Textures',
    authors: 'Koki Nagano, Jaewoo Seo, Jun Xing, Lingyu Wei, Zimo Li, Shunsuke Saito, Aviral Agarwal, Jens Fursund, Hao Li',
    publication: 'Proceedings of the 11th ACM SIGGRAPH Conference and Exhibition in Asia 2018',
    eventName: 'SIGGRAPH Asia 2018',
    links: [
      {name:'[paper]', link:'http://www.hao-li.com/publications/papers/siggraphAsia2018PAGAN.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=_JMx5VN1eeM'},
    ],
    previewPic: thumb6,
  },
  {
    name: 'Avatar Digtization from a Single Image for Real-Time Rendering',
    authors: 'Liwen Hu, Shunsuke Saito, Lingyu Wei, Koki Nagano, Jaewoo Seo, Jens Fursund, Carrie Sun, Yen-Chun Chen, Hao Li',
    publication: 'Proceedings of the 10th ACM SIGGRAPH Conference and Exhibition in Asia 2017',
    eventName: 'SIGGRAPH Asia 2017',
    links: [
      {name:'[paper]', link:'http://www.hao-li.com/publications/papers/siggraphAsia2017ADFSIFRTR.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=dERjpAaoNjk'},
    ],
    previewPic: thumb7,
  },
  {
    name: 'FLAME: Learning a Model of Facial Shape and Expression from 4D Scans',
    authors: 'Tianye Li, Timo Bolkart, Michael J. Black, Hao Li, Javier Romero',
    publication: 'Proceedings of the 10th ACM SIGGRAPH Conference and Exhibition in Asia 2017',
    eventName: 'SIGGRAPH Asia 2017',
    links: [
      {name:'[paper]', link:'http://www.hao-li.com/publications/papers/siggraphAsia2017LMFSEFS.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=36rPTkhiJTM'},
      {name:'[project]', link:'https://flame.is.tue.mpg.de/'}
    ],
    previewPic: thumb8,
  },
  {
    name: 'Realistic Dynamic Facial Textures from a Single Image using GANs',
    authors: 'Kyle Olszewski, Zimo Li, Chao Yang, Yi Zhou, Ronald Yu, Zeng Huang, Sitao Xiang, Shunsuke Saito, Pushmeet Kohli, Hao Li',
    publication: 'Proceedings of the IEEE International Conference on Computer Vision 2017',
    eventName: 'ICCV 2017',
    links: [
      {name:'[paper]', link:'http://www.hao-li.com/publications/papers/iccv2017RDFTFSIUG.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=R-kkq4xxVNw'},
    ],
    previewPic: thumb9,
  },
  {
    name: 'Photorealistic Facial Texture Inference using Deep Neural Networks',
    authors: 'Shunsuke Saito, Lingyu Wei, Liwen Hu, Koki Nagano, Hao Li',
    publication: 'Proceedings of the 30th IEEE International Conference on Computer Vision and Pattern Recognition 2017',
    eventName: 'CVPR 2017 ',
    links: [
      {name:'[paper]', link:'http://arxiv.org/pdf/1612.00523v1.pdf'},
      {name:'[video]', link:'https://www.youtube.com/watch?v=qX8AIRsFmTA'},
    ],
    previewPic: thumb10,
  }
];
