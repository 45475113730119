import React, {useState} from 'react'
import styled from 'styled-components'
import { device } from '../models/device'
import { theme } from '../models/theme'
import { Research } from '../models/research'
import { RegularContentDiv, ContentSection, FullPageContentSection, StyledPageDiv, StyledSpacerDiv } from '../general/GeneralStyles'
import UnderlineRadio from '../components/UnderlineRadio'
import ContentOverImage from '../components/ContentOverImage'
import ImageBackgroundDiv from '../components/ImageBackgroundDiv'

import teaser from '../images/research/ResearchTeaser.jpg'
import TintContent from '../components/TintContent'
import { Helmet } from 'react-helmet-async'

export const FullTopFlex = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
`

export const TitleOverDiv = styled.div`
    position: absolute;

    h1 {
        position: relative;
       font-size: 2rem;
    }
`

export const BottomAlignedSection = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
`

export const BottomColumnSection = styled.div`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    display: block;
    h1, h2, h3, p {
        text-align: left;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-block: 0.5rem;
    }
`

export const PubDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
    gap: 2rem;

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: center;
    }
`

export const PubPreview = styled.img`
    width: 12rem;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: ${props => props.theme.borderRadius};
    @media ${device.mobile} {
        width: 100%;
    }
    filter: saturate(0.5);
`

export const PubTextDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: auto;
    height: auto;

    h2 {
        font-style: italic;
    }

    h2, a {
        font-weight: 400;
    }

    h2, p {
        margin-block: 0;
    }

    h2, p, a {
        text-align: left;
    }

    @media ${device.mobile} {
        p {
            font-size: 1rem;
        }
    }
`

export const LinkDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    width: auto;
    height: auto;
    gap: 0.25rem;
`


export const PublicationEvent = styled.span`
    font-weight: 400;
    color: ${props => props.theme.accentVariant};
`

export const ResearchProjects = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    width: auto;
    height: auto;
    gap: 1rem;
`

export default function VFXResearch() {
    return(
        <StyledPageDiv>
            <Helmet>
                <title>Research</title>
                <meta name="description" content="In our relentless pursuit of staying at the forefront of innovation and maintining a competitive edge, Pinscreen is committed in engaging actively in fundamental research." key="desc" />
                <meta property="og:title" content="Research" />
                <meta
                property="og:description"
                content="In our relentless pursuit of staying at the forefront of innovation and maintining a competitive edge, Pinscreen is committed in engaging actively in fundamental research."
                />
            </Helmet>
            <StyledSpacerDiv/>
            <RegularContentDiv>
                <FullPageContentSection>
                    <ImageBackgroundDiv source={teaser} brightness='0.5'>
                        <FullTopFlex>
                            <h1>
                                Research
                            </h1>
                            <BottomAlignedSection>
                                <BottomColumnSection>
                                    <h3>
                                        Pioneering Generative AI Research for VFX and Digital Humans
                                    </h3>
                                    <p>
                                        The recent breakthroughs in generative AI for image and video creation are poised to revolutionize the film and entertainment
                                        industry, changing the way we produce high-quality film content. Our mission embarks on embracing cutting-edge technologies to
                                        address VFX challenges that were once deemed either impossible or prohibitively expensive using traditional CG methods, while in
                                        the long term, aiming for more generalized production-level video generation and authoring capabilities.
                                    </p>
                                    <p>
                                        In our relentless pursuit of staying at the forefront of innovation and maintining a competitive edge, Pinscreen is committed in
                                        engaging actively in fundamental research. This commitment is is evident in our exploration of new technological capabilities
                                        through scientific publications, especially in the areas of Computer Vision, Computer Graphics, and Machine Learning.
                                    </p>
                                    <p>
                                        Since our inception, we have published 50 papers at top conferences (CVPR, ICCV, ECCV, SIGGRAPH, NeurIPS, etc.) and
                                        generated 7 patents, among others. Our groundbreaking work has earned prestitigious recognitions, being showcased at the
                                        World Economic Forum in Davos and 8 presentations at SIGGRAPH Real-Time Live! Our collaborative efforts extend to
                                        partnerships with leading academic institutions (MBZUAI, UC Berkeley, ETH Zurich, USC, etc.), and we actively participate in
                                        government-funded research programs such as DARPA.
                                    </p>
                                </BottomColumnSection>
                            </BottomAlignedSection>
                        </FullTopFlex>
                    </ImageBackgroundDiv>
                </FullPageContentSection>
                <ContentSection>
                    <UnderlineRadio justifyOptions={'flex-start'} optionList={[{text: 'Key Publications', tag: 'all'}]}>
                    <ResearchProjects>
                        { Research.map( (project, index) => 
                            <PubDiv href={'vfx-projects/details/' + project.url}>
                                <TintContent tintColor='rgba(25, 125, 80, 0.1)'>
                                    <PubPreview src={project.previewPic}/>
                                </TintContent>
                                <PubTextDiv>
                                    <h2>
                                        { project.name }
                                    </h2>
                                    <p>
                                        { project.authors }
                                    </p>
                                    <p>
                                        {project.publication + ' – '}
                                        <PublicationEvent>
                                            { project.eventName }
                                        </PublicationEvent>
                                    </p>
                                    <LinkDiv>
                                        {project.links.map( (link, index) =>
                                        <a href={link.link} target="_blank">
                                            { link.name }
                                        </a>
                                        )}
                                    </LinkDiv>
                                </PubTextDiv>
                            </PubDiv>
                        )}
                    </ResearchProjects>
                    </UnderlineRadio>
                </ContentSection>
            </RegularContentDiv>
        </StyledPageDiv>
    )
}