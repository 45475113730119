import styled from "styled-components";
import React, { useState } from 'react'
import TextButton from "./TextButton";
import { theme } from "../models/theme"

import 'swiper/css/bundle';
import { device } from "../models/device";

export const EncapsulatingDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`

export const TopDivForm = styled.form`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    justify-content: ${props => props.justifyOptions};
    width: 100%;
    height: auto;
    border-bottom: 2px solid white;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
`

export const ArrowContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(50%);
    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: space-between;
    z-index: 10; 
`

export const ArrowButton = styled.button`
    position: relative;
    width: auto;
    height: auto;
    font-size: 2rem;
    color: ${props => props.theme.textLight};
    background: none;
    transition: all ease 0.2s;
    font-family: 'swiper-icons';
    font-variant: initial;

    :hover {
        transform: scale(1.05);
    }

    @media ${device.mobile} {
        display: none;
    }
`

export const ButtonStyleH3 = styled.h3`
    font-size: 1.25rem;
    margin: 0;
    margin-block: 0;
`

export default function UnderlineRadio(props) {
    const [currentTag, setCurrentTag] = useState(props.optionList[0].tag);
    return(
        <EncapsulatingDiv>
            <TopDivForm justifyOptions={props.justifyOptions}>
                {
                    (props.optionList.length > 1) ?
                    props.optionList.map((option) => <TextButton textColor={ option.tag == currentTag ? theme.accent : theme.textLight } handleClick={() => {props.handleSubmit(option.tag); setCurrentTag(option.tag);}}>{option.text}</TextButton>)
                    :
                    <ButtonStyleH3>{props.optionList[0].text}</ButtonStyleH3>
                }
            </TopDivForm>
            {
                props.navigationArrows ?
            <ArrowContainer>
                    <ArrowButton onClick={
                        () => {
                            const indexMinusOne = (props.optionList.findIndex( (e) => e.tag == currentTag )) - 1;
                            if(indexMinusOne >= 0) {
                                const newTag = props.optionList[indexMinusOne].tag;
                                props.handleSubmit(newTag);
                                setCurrentTag(newTag);
                            }
                        }
                        }>prev</ArrowButton>
                    <ArrowButton onClick={
                        () => {
                            const indexPlusOne = (props.optionList.findIndex( (e) => e.tag == currentTag )) + 1;
                            if(indexPlusOne < props.optionList.length) {
                                const newTag = props.optionList[indexPlusOne].tag;
                                props.handleSubmit(newTag);
                                setCurrentTag(newTag);
                            }
                        }
                        }>next</ArrowButton>
            </ArrowContainer>
            :
            <></>
            }
            {props.children}
        </EncapsulatingDiv>
    )
}