import benioffMarc from '../images/board/Marc.jpg'
import shumHarry from '../images/board/Harry.jpg'
import bradskiGary from '../images/board/Gary.jpg'
import krausJoe from '../images/board/Joe.jpg'




export const AdvisorBoard = [
  {
    name: 'Marc Benioff',
    title: 'CEO, Salesforce',
    website: '',
    profilePic: benioffMarc,
  },
  {
    name: 'Harry Shum',
    title: 'EVP of AI & Research, Microsoft',
    website: '',
    profilePic: shumHarry,
  },
  {
    name: 'Gary Bradski',
    title: 'President & CEO, OpenCV',
    website: '',
    profilePic: bradskiGary,
  },
  {
    name: 'Joe Kraus',
    title: 'Venture Partner, Google Ventures',
    website: '',
    profilePic: krausJoe,
  },
];