

import styled from "styled-components";
import MinimalVideo from "./MinimalVideo";

 const ImageContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`

 const BackgroundVideoCover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
`

 const BackgroundVideoContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`

 const ContentOverContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`

 const Contents = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export default function VideoBackgroundDiv(props){

    return (
    <ImageContainer>
        <BackgroundVideoCover>
            <BackgroundVideoContainer>
                <MinimalVideo looping={true} playing={props.playing} source={props.source} previewImage={props.previewImage} aspectRatio={props.aspectRatio} maxHeightLimit={props.maxHeightLimit} videoBrightness={props.brightness}/>
            </BackgroundVideoContainer>
        </BackgroundVideoCover>
        <ContentOverContainer>
            <Contents>
                {props.children}
            </Contents>
        </ContentOverContainer>
    </ImageContainer >
  );
}
