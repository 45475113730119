import React, {useState} from 'react'
import styled from 'styled-components'
import { theme } from '../models/theme'
import { RegularContentDiv, ContentSection, GradientCover, FullPageContentSection, FullPageMarginlessExpanderContentSectionMobile, StyledPageDiv, StyledSpacerDiv } from '../general/GeneralStyles'
import UnderlineRadio from '../components/UnderlineRadio'
import { OpenJobs } from '../models/openjobs'
import PillButton from '../components/PillButton'

import jobsBackground from '../images/jobs/background.jpeg'

import ContentOverImage from '../components/ContentOverImage'
import ImageBackgroundDiv from '../components/ImageBackgroundDiv'
import { Helmet } from 'react-helmet-async'

export const JobsDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
`

export const JobDiv = styled.div`
    display: inline-block;

    h1, h2, p {
        display: inline;
        text-align: left;
    }

    h2 {
        font-size: 1.25rem;
        font-weight: 400;
    }

    text-align: left;
    
    margin-block: 0.5rem;
`

export const RadioContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
`

export const BottomTextContainer = styled.div`
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2, p {
        text-align: center;
    }
`

export default function Jobs() {
    return(
        <StyledPageDiv>
            <Helmet>
                <title>Join Us</title>
                <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                <meta property="og:title" content="Join Us" />
                <meta
                property="og:description"
                content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                />
            </Helmet>
            <StyledSpacerDiv/>
            <FullPageMarginlessExpanderContentSectionMobile>
                <ImageBackgroundDiv source={jobsBackground} brightness='0.6'>
                    <GradientCover>
                        <ContentSection>
                            <h1>
                                Join Us
                            </h1>
                            <RadioContainer>
                                <UnderlineRadio justifyOptions={'flex-start'} optionList={[{text: 'Open Positions', tag: 'all'}]}>
                                    <JobsDiv>
                                        { OpenJobs.map( (job, index) => 
                                        <JobDiv>
                                                <h2>
                                                    { job.title }
                                                </h2>
                                                <p>
                                                    {' – ' + job.skills }
                                                </p>
                                        </JobDiv>
                                        )}
                                    </JobsDiv>
                                </UnderlineRadio>
                            </RadioContainer>
                            <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"get in touch"}/>
                        </ContentSection>
                    </GradientCover>
                </ImageBackgroundDiv>
            </FullPageMarginlessExpanderContentSectionMobile>
            <ContentSection>
                <BottomTextContainer>
                    <h2>
                        Don't See Yourself Above?
                    </h2>
                    <p>
                        If none of the above positions look like a match but you still feel strongly you could contribute please reach out! We're open to accepting new team members from a variety of skillsets and backgrounds. Shoot us an email at <a href='mailto:jobs@pinscreen.com'>jobs@pinscreen.com</a> and let us know what you bring to the table!
                    </p>
                </BottomTextContainer>
            </ContentSection>
        </StyledPageDiv>
    )
}