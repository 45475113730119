import dubai_embraces_ai_to_disrupt_the_film_sector from '../images/press/dubai_embraces_ai_to_disrupt_the_film_sector.png'
import digital_humans_in_retail from '../images/press/digital_humans_in_retail.png'
import cnn_live_from_davos_deepfaked_with_nina_dos_santos from '../images/press/cnn_live_from_davos_deepfaked_with_nina_dos_santos.png'
import perfectly_real_deepfakes_will_arrive_in_6_months_to_a_year_technology_pioneer_hao_li_says from '../images/press/perfectly_real_deepfakes_will_arrive_in_6_months_to_a_year_technology_pioneer_hao_li_says.png'
import will_you_still_own_your_face_after_deepfake_gets_to_it from '../images/press/will_you_still_own_your_face_after_deepfake_gets_to_it.gif'
import and_i_never_said_a_word from '../images/press/and_i_never_said_a_word.jpg'
import deep_dive_into_deepfakes_frighteningly_real_and_sometimes_used_for_the_wrong_things from '../images/press/deep_dive_into_deepfakes_frighteningly_real_and_sometimes_used_for_the_wrong_things.png'
import tra_meno_di_un_anno_il_deepfake_sar_alla_portata_di_tutti_parola_del_massimo_esperto_hao_li from '../images/press/tra_meno_di_un_anno_il_deepfake_sar_alla_portata_di_tutti_parola_del_massimo_esperto_hao_li.webp'
import liars_dividend_the_more_we_learn_about_deepfakes_the_more_dangerous_they_become from '../images/press/liars_dividend_the_more_we_learn_about_deepfakes_the_more_dangerous_they_become.png'
import an_interview_with_hao_li_of_digital_humans_and_virtual_beings from '../images/press/an_interview_with_hao_li_of_digital_humans_and_virtual_beings.png'
import ai_avatars_virtual_assistants_and_deepfakes_a_realtime_look_acm_siggraph_blog from '../images/press/ai_avatars_virtual_assistants_and_deepfakes_a_realtime_look_acm_siggraph_blog.png'
import deepfakes_will_create_every_face_we_see_online_in_ai_infocalypse from '../images/press/deepfakes_will_create_every_face_we_see_online_in_ai_infocalypse.webp'
import were_one_step_closer_to_consumeraccessible_immersive_teleportation_acm_siggraph_blog from '../images/press/were_one_step_closer_to_consumeraccessible_immersive_teleportation_acm_siggraph_blog.png'
import hao_li_ceo_and_cofounder_pinscreen from '../images/press/hao_li_ceo_and_cofounder_pinscreen.png'
import new_realtime_hybrid_ai_cgi_face_replacement from '../images/press/new_realtime_hybrid_ai_cgi_face_replacement.png'
import deepfake_hao_li_warnt_davor_dass_technik_ausser_kontrolle_gert from '../images/press/deepfake_hao_li_warnt_davor_dass_technik_ausser_kontrolle_gert.png'
import deepfakes_a_threat_to_democracy_or_just_a_bit_of_fun from '../images/press/deepfakes_a_threat_to_democracy_or_just_a_bit_of_fun.png'
import fake_trump_video_how_to_spot_deepfakes_on_facebook_and_youtube_ahead_of_the_presidential_election from '../images/press/fake_trump_video_how_to_spot_deepfakes_on_facebook_and_youtube_ahead_of_the_presidential_election.jpg'
import images_virtuelles_vidos_truques_peuton_encore_croire_ce_que_lon_voit_ from '../images/press/images_virtuelles_vidos_truques_peuton_encore_croire_ce_que_lon_voit_.png'
import deep_fakes_wow_das_entwickelt_sich_schneller_als_ich_dachte from '../images/press/deep_fakes_wow_das_entwickelt_sich_schneller_als_ich_dachte.png'
import prepare_for_the_deepfake_era_of_web_video from '../images/press/prepare_for_the_deepfake_era_of_web_video.png'
import a_deepfake_putin_and_the_future_of_ai_take_center_stage_at_emtech from '../images/press/a_deepfake_putin_and_the_future_of_ai_take_center_stage_at_emtech.png'
import a_fake_interview_with_vladimir_putin_demonstrates_how_convincing_deepfakes_could_be_created_in_realtime_in_just_a_matter_of_years from '../images/press/a_fake_interview_with_vladimir_putin_demonstrates_how_convincing_deepfakes_could_be_created_in_realtime_in_just_a_matter_of_years.png'
import the_worlds_top_deepfake_artist_wow_this_is_developing_more_rapidly_than_i_thought from '../images/press/the_worlds_top_deepfake_artist_wow_this_is_developing_more_rapidly_than_i_thought.jpg'
import usc_professor_at_forefront_of_deepfake_technology from '../images/press/usc_professor_at_forefront_of_deepfake_technology.png'
import realtime_live_stuns_at_siggraph_2019_acm_siggraph_blog from '../images/press/realtime_live_stuns_at_siggraph_2019_acm_siggraph_blog.png'
import deepfake_detection_algorithms_will_never_be_enough from '../images/press/deepfake_detection_algorithms_will_never_be_enough.png'
import fake_videos_pose_a_challenge_for_lawmakers from '../images/press/fake_videos_pose_a_challenge_for_lawmakers.png'
import a_new_deepfake_detection_tool_should_keep_world_leaders_safefor_now from '../images/press/a_new_deepfake_detection_tool_should_keep_world_leaders_safefor_now.png'
import why_deepfake_videos_are_becoming_more_difficult_to_detect from '../images/press/why_deepfake_videos_are_becoming_more_difficult_to_detect.png'
import outfitting_avatars_to_cross_the_uncanny_valley from '../images/press/outfitting_avatars_to_cross_the_uncanny_valley.png'
import ai_at_siggraph_part_2_pinscreen_at_real_time_live from '../images/press/ai_at_siggraph_part_2_pinscreen_at_real_time_live.png'
import spotting_fake_news_in_a_world_with_manipulated_video from '../images/press/spotting_fake_news_in_a_world_with_manipulated_video.png'
import fake_videos_are_on_the_rise_as_they_become_more_realistic_seeing_shouldnt_always_be_believing from '../images/press/fake_videos_are_on_the_rise_as_they_become_more_realistic_seeing_shouldnt_always_be_believing.png'
import professors_research_contributed_to_iphone_x_daily_trojan from '../images/press/professors_research_contributed_to_iphone_x_daily_trojan.png'
import face_me_part_1_photorealistic_facial_texture_from_a_single_still from '../images/press/face_me_part_1_photorealistic_facial_texture_from_a_single_still.png'
import dieses_neuronale_netz_erschafft_3dgesichter_aus_unscharfen_fotos from '../images/press/dieses_neuronale_netz_erschafft_3dgesichter_aus_unscharfen_fotos.png'
import pinscreen_launches_with_hightech_distractions_for_a_nervewracking_election_techcrunch from '../images/press/pinscreen_launches_with_hightech_distractions_for_a_nervewracking_election_techcrunch.png'
import stealth_facetracking_startup_pinscreen_raises_18_million from '../images/press/stealth_facetracking_startup_pinscreen_raises_18_million.jpg'
import inside_mbzuais_metaverse_lab_for_an_immersive_digital_presence from '../images/press/inside_mbzuais_metaverse_lab_for_an_immersive_digital_presence.png'
import here_come_the_fake_videos_too_published_2018 from '../images/press/here_come_the_fake_videos_too_published_2018.png'
import deepfakes_in_the_2024_election_what_you_need_to_know from '../images/press/deepfakes_in_the_2024_election_what_you_need_to_know.png'
import ai_has_won_2023_will_the_metaverse_win_2024 from '../images/press/ai_has_won_2023_will_the_metaverse_win_2024.webp'
import uae_project_to_develop_3d_avatars_of_teachers_students_for_virtual_classes from '../images/press/uae_project_to_develop_3d_avatars_of_teachers_students_for_virtual_classes.png'
import the_neural_rendering_of_the_champion from '../images/press/the_neural_rendering_of_the_champion.png'
import ai_avatars_virtual_assistants_and_deepfakes_a_realtime_look__acm_siggraph_blog from '../images/press/ai_avatars_virtual_assistants_and_deepfakes_a_realtime_look__acm_siggraph_blog.png'
import were_one_step_closer_to_consumeraccessible_immersive_teleportation__acm_siggraph_blog from '../images/press/were_one_step_closer_to_consumeraccessible_immersive_teleportation__acm_siggraph_blog.jpg'
import fake_naked_photos_of_thousands_of_women_shared_online from '../images/press/fake_naked_photos_of_thousands_of_women_shared_online.jpg'
import realtime_live_winner_monoport from '../images/press/realtime_live_winner_monoport.png'
import these_faces_are_not_real from '../images/press/these_faces_are_not_real.png'
import my_glitchy_glorious_day_at_a_conference_for_virtual_beings from '../images/press/my_glitchy_glorious_day_at_a_conference_for_virtual_beings.png'
import how_facebook_twitter_and_google_are_working_to_prevent_deepfakes_from_fooling_you from '../images/press/how_facebook_twitter_and_google_are_working_to_prevent_deepfakes_from_fooling_you.jpg'
import wait_is_fake_news_about_to_become_even_more_frightening from '../images/press/wait_is_fake_news_about_to_become_even_more_frightening.png'
import all_of_apples_facetracking_tech_behind_the_iphone_xs_animoji from '../images/press/all_of_apples_facetracking_tech_behind_the_iphone_xs_animoji.png'
import facebook_banned_deepfakes from '../images/press/facebook_banned_deepfakes.jpg'
import the_worlds_top_deepfake_artist_is_wrestling_with_the_monster_he_created from '../images/press/the_worlds_top_deepfake_artist_is_wrestling_with_the_monster_he_created.jpg'
import breaking_news from '../images/press/breaking_news.png'

export const PressLinks = [
  {
    "year": "2024",
    "links": [
      {
        "mmdd": "January 18",
        "publication": "CNBC",
        "link": "https://www.cnbc.com/video/2024/01/18/deepfakes-in-the-2024-election-what-you-need-to-know.html?fbclid=IwAR2Iix2xtZP_hOtS9z-VhzEuxlZ7xwTtgNrUZ6LP0Zxl9R_GEbgtQ2I7dLg",
"image": deepfakes_in_the_2024_election_what_you_need_to_know,
"description": "Former Sen. Heidi Heitkamp and Hao Li, Pinscreen founder and CEO, join 'The Exchange' to discuss detecting deepfakes, deepfake election risks, and more.",
      },
    ],
  },
  {
    "year": "2023",
    "links": [
      {
        "mmdd": "December 29",
        "publication": "Forbes",
        "link": "https://www.forbes.com/sites/davidwalcott/2023/12/29/ai-has-won-2023-will-the-metaverse-win-2024/?sh=6ddcecb2853c",
"image": ai_has_won_2023_will_the_metaverse_win_2024,
"description": "In the exhilarating landscape of today's technological advancements, we stand on the cusp of 2024 with heightened expectations...",
      },
      {
        "mmdd": "November",
        "publication": "Wired",
        "link": "https://wired.me/technology/do-you-lose-face-ownership-to-deepfake/",
"image": will_you_still_own_your_face_after_deepfake_gets_to_it,
"description": "This summer, Hollywood has peeked into a horrifying future where deepfake and its invasion of privacy eat up its creatives."
      },
      {
        "mmdd": "September",
        "publication": "AGBI",
        "link": "https://www.agbi.com/analysis/tech/2023/09/dubai-embraces-ai-to-disrupt-the-film-sector/?utm_source=linkedin&utm_medium=social&utm_campaign=awa&utm_content=ai_film_sector-img&utm_term=feed-tech",
"image": dubai_embraces_ai_to_disrupt_the_film_sector,
"description": "While AI is an important factor in the current Hollywood strike, Dubai and the Gulf’s nascent film sector views it with more optimism",
      },
      {
        "mmdd": "May",
        "publication": "Gulfnews",
        "link": "https://gulfnews.com/uae/education/uae-project-to-develop-3d-avatars-of-teachers-students-for-virtual-classes-1.95540258",
"image": uae_project_to_develop_3d_avatars_of_teachers_students_for_virtual_classes,
"description": "MBZUAI professor says the tech could even create AI-powered avatars of historical figures",
      },
      {
        "mmdd": "March",
        "publication": "FXGuide",
        "link": "https://www.fxguide.com/quicktakes/and-i-never-said-a-word/",
"image": and_i_never_said_a_word,
"description": "We've been working on more (fun) machine learning demos. Respeecher and Pinscreen join us to demonstrate advanced AI Facial Re-enactment.",
      },
    ],
  },
  {
    "year": "2022",
    "links": [
      {
        "mmdd": "October",
        "publication": "Wired",
        "link": "https://wired.me/technology/inside-mbzuais-metaverse-lab-for-an-immersive-digital-presence/",
"image": inside_mbzuais_metaverse_lab_for_an_immersive_digital_presence,
"description": "In MBZUAI's new Metaverse Lab Dr. Hao Liaims to radically improve your digital self."
      },
      {
        "mmdd": "April",
        "publication": "FXGuide",
        "link": "https://www.fxguide.com/fxfeatured/the-neural-rendering-of-the-champion/",
"image": the_neural_rendering_of_the_champion,
"description": "The world's first full neural rendered dialogue replacement full feature film.",
      },
    ],
  },
  {
    "year": "2021",
    "links": [
      {
        "mmdd": "October",
        "publication": "Channel News Asia",
        "link": "https://www.channelnewsasia.com/singapore/deepfakes-ai-security-threat-face-swapping-2252161",
"image": deep_dive_into_deepfakes_frighteningly_real_and_sometimes_used_for_the_wrong_things,
"description": "SINGAPORE: The video started with what appeared to be former US president Barack Obama sitting in the White House Oval Office. There was no mistaking the identity of the person in the video, that is, until he began to speak. \"President Trump is a total and complete ****. Now, you see I will",
      },
      {
        "mmdd": "April",
        "publication": "Wired",
        "link": "https://www.wired.it/play/cinema/2021/04/24/intervista-deepfake-hao-li-massimo-esperto/",
"image": tra_meno_di_un_anno_il_deepfake_sar_alla_portata_di_tutti_parola_del_massimo_esperto_hao_li,
"description": "Ha creato Paul Walker digitale per Fast & Furious. E qui spiega come distingueremo i deepfake dalla realtà e quale valore avrà l’interazione vera",
      },
      {
        "mmdd": "April",
        "publication": "Business Insider",
        "link": "https://www.businessinsider.com/deepfakes-liars-dividend-explained-future-misinformation-social-media-fake-news-2021-4",
"image": liars_dividend_the_more_we_learn_about_deepfakes_the_more_dangerous_they_become,
"description": "\"If anything can be faked, including videos, then everything can be denied,\" deepfake expert Nina Shick told Insider.",
      },
      {
        "mmdd": "March",
        "publication": "Toms Hardware",
        "link": "https://www.tomshw.it/culturapop/an-interview-with-hao-li-of-digital-humans-and-virtual-beings/",
"image": an_interview_with_hao_li_of_digital_humans_and_virtual_beings,
"description": "In occasion of his talk at VFXRio Live, Hao Li tells us how deep learning and AI manipolation can create digital humans, and how these technologies will change our virtual presence",
      },
      {
        "mmdd": "February",
        "publication": "FXGuide",
        "link": "https://www.fxguide.com/fxfeatured/digital-humans-in-retail/",
"image": digital_humans_in_retail,
"description": "ZOZO Japan pushes extensive Digital Human research for new online retail customer experiences. ",
      },
      {
        "mmdd": "January",
        "publication": "ACM SIGGRAPH Blog",
        "link": "https://blog.siggraph.org/2021/01/ai-avatars-virtual-assistants-and-deepfakes-a-real-time-look.html/",
"image": ai_avatars_virtual_assistants_and_deepfakes_a_realtime_look__acm_siggraph_blog,
"description": "Hao Li shares the process of developing AI avatars and virtual assistants, emerging trends in the deepfakes space, and his advice for those planning to submit to a future SIGGRAPH conference.",
      },
    ],
  },
  {
    "year": "2020",
    "links": [
      {
        "mmdd": "October",
        "publication": "Daily Star",
        "link": "https://www.dailystar.co.uk/news/latest-news/deepfakes-create-every-face-see-22852421",
"image": deepfakes_will_create_every_face_we_see_online_in_ai_infocalypse,
"description": "EXCLUSIVE The ability of artificial intelligence (AI) to create completely believable fake human faces and voices is growing too quickly for the fake-detectors to keep up, an expert has worryingly claimed",
      },
      {
        "mmdd": "October",
        "publication": "ACM SIGGRAPH Blog",
        "link": "https://blog.siggraph.org/2020/10/were-one-step-closer-to-consumer-accessible-immersive-teleportation.html/",
"image": were_one_step_closer_to_consumeraccessible_immersive_teleportation__acm_siggraph_blog,
"description": "Resesarchers from Pinscreen and USC ICT discuss their award-winning, deep-learning-based approach for immersive teleportation from SIGGRAPH 2020 Real-Time Live!.",
      },
      {
        "mmdd": "October",
        "publication": "BBC",
        "link": "https://www.bbc.co.uk/news/technology-54584127",
"image": fake_naked_photos_of_thousands_of_women_shared_online,
"description": "Educating about the dangers of deepfakes, Hao Li discuss with the host of a BBC report on the subject",
      },
      {
        "mmdd": "August",
        "publication": "FXGuide",
        "link": "https://www.fxguide.com/fxfeatured/real-time-live-winner-monoport/",
"image": realtime_live_winner_monoport,
"description": "The winning submission from Real-Time Live: Monoport",
      },
      {
        "mmdd": "August",
        "publication": "Chaos Group Blog",
        "link": "https://www.chaos.com/cg-garage/hao-li-ceo-and-co-founder-pinscreen",
"image": hao_li_ceo_and_cofounder_pinscreen,
"description": "Soon, our virtual clones will look and act just like us. Meet genius Hao Li and find out how his Pinscreen software captures and creates accurate digital humans.",
      },
      {
        "mmdd": "July",
        "publication": "Wired",
        "link": "https://www.wired.com/story/2020-virtual-beings-summit/",
"image": my_glitchy_glorious_day_at_a_conference_for_virtual_beings,
"description": "Spectators spent much of the time debating who was real and who was fake.",
      },
      {
        "mmdd": "July",
        "publication": "Reuters",
        "link": "https://www.reuters.com/graphics/CYBER-DEEPFAKE/ACTIVIST/nmovajgnxpa/index.html",
"image": these_faces_are_not_real,
"description": "Many \"deepfakes\" look odd, but technology is fast improving",
      },
      {
        "mmdd": "February",
        "publication": "FXGuide",
        "link": "https://www.fxguide.com/fxfeatured/new-real-time-hybrid-ai-cgi-face-replacement/",
"image": new_realtime_hybrid_ai_cgi_face_replacement,
"description": "Real-time paGAN 2 on CG Agents and Avatars",
      },
      {
        "mmdd": "January",
        "publication": "Neue Zürcher Zeitung",
        "link": "https://www.nzz.ch/wirtschaft/deepfake-hao-li-warnt-davor-dass-technik-ausser-kontrolle-geraet-ld.1536691?mktcid=smch&mktcval=twpost_2020-01-30",
"image": deepfake_hao_li_warnt_davor_dass_technik_ausser_kontrolle_gert,
"description": "Hao Li kann jeden in George W. Bush verwandeln und Paul Walker zurück ins Leben holen. Kaum einer manipuliert Videos so wie er. Heute kämpft Li gegen das Monster, das er mit erschaffen hat.",
      },
      {
        "mmdd": "January",
        "publication": "BBC",
        "link": "https://www.bbc.com/news/business-51204954?fbclid=IwAR3rFko8n50uZu8MUcerqclLRf_ujPihDzK7wj6Z4-X9ccYUN0GA6z3dSLc",
"image": deepfakes_a_threat_to_democracy_or_just_a_bit_of_fun,
"description": "Deepfakes, or computer-generated images of people, can be dangerous for democracy, warn experts.",
      },
      {
        "mmdd": "January",
        "publication": "USA Today",
        "link": "https://www.usatoday.com/story/tech/2020/01/08/deepfakes-facebook-youtube-donald-trump-election/2836428001/?fbclid=IwAR2wdYkLKgQtVInu0rT4_3fQK4Q9EPWUJPwa-6idz4vrPFgR81LjGgkklNE",
"image": fake_trump_video_how_to_spot_deepfakes_on_facebook_and_youtube_ahead_of_the_presidential_election,
"description": "Deep fake videos could be used to spread disinformation and mislead voters about Donald Trump or a Democratic challenger in the presidential election",
      },
      {
        "mmdd": "January",
        "publication": "CNN",
        "link": "https://youtu.be/POseJ0cESp4?si=BSyNRJipRU-kl-JV",
"image": cnn_live_from_davos_deepfaked_with_nina_dos_santos,
"description": "CNN correspondent Nina Dos Santos checking out new real-time deepfake technology developed by Pinscreen and interview with Hao Li at the World Economic Forum...",
      },
      {
        "mmdd": "January",
        "publication": "Fox Business",
        "link": "https://twitter.com/FoxBusinessAM/status/1215625094183170049",
"image": facebook_banned_deepfakes,
"description": "Facebook has banned 'Deepfakes' to combat misinformation. Simonetti Lauren spoke with Hao Li about the danger of 'Deepfakes' and how to distinguish what is real and what is fake."
      },
    ],
  },
  {
    "year": "2019",
    "links": [
      {
        "mmdd": "October",
        "publication": "Le Parisien",
        "link": "https://www.leparisien.fr/high-tech/images-virtuelles-videos-truquees-peut-on-encore-croire-ce-que-l-on-voit-25-10-2019-8180148.php#xtor=AD-32280599",
"image": images_virtuelles_vidos_truques_peuton_encore_croire_ce_que_lon_voit_,
"description": "LE PARISIEN WEEK-END. Aujourd’hui, les deepfakes, vidéos truquées hyper-réalistes, mentent mieux que jamais. Décryptage d’un phénomène aussi",
      },
      {
        "mmdd": "October",
        "publication": "Heise Online",
        "link": "https://www.heise.de/hintergrund/Wow-das-entwickelt-sich-schneller-als-ich-dachte-4548955.html",
"image": deep_fakes_wow_das_entwickelt_sich_schneller_als_ich_dachte,
"description": "Deep Fake-Manipulationen werden bald nicht mehr von der Realität unterscheidbar sein, warnt Hao Li von der University of Southern California.",
      },
      {
        "mmdd": "October",
        "publication": "Wired",
        "link": "https://www.wired.com/story/prepare-deepfake-era-web-video/",
"image": prepare_for_the_deepfake_era_of_web_video,
"description": "“We’re going to get more and more of this content, and it’s probably going to get of better quality,” says Sam Gregory, of the human-rights nonprofit Witness.",
      },
      {
        "mmdd": "September",
        "publication": "CNBC",
        "link": "https://www.cnbc.com/2019/09/20/hao-li-perfectly-real-deepfakes-will-arrive-in-6-months-to-a-year.html",
"image": perfectly_real_deepfakes_will_arrive_in_6_months_to_a_year_technology_pioneer_hao_li_says,
"description": "Soon, it's going to get to the point where there is no way that we can actually detect [deepfakes] anymore, so we have to look at other types of solutions,\" says Hao Li.",
      },
      {
        "mmdd": "September",
        "publication": "PC MAG",
        "link": "https://www.pcmag.com/news/a-deepfake-putin-and-the-future-of-ai-take-center-stage-at-emtech",
"image": a_deepfake_putin_and_the_future_of_ai_take_center_stage_at_emtech,
"description": "Deepfake technology got a lot of attention, but I was more interested in the future directions for AI research.",
      },
      {
        "mmdd": "September",
        "publication": "CNBC",
        "link": "https://www.cnbc.com/2019/09/29/how-facebook-twitter-and-google-work-to-detect-and-prevent-deepfakes.html",
"image": how_facebook_twitter_and_google_are_working_to_prevent_deepfakes_from_fooling_you,
"description": "Deepfakes have started to appear everywhere, posing an increased threat to businesses, politicians and celebrities. Now, Facebook is spending $10 million on efforts to detect the AI-created videos of real people saying and doing fake things. Google, Microsoft and Twitter are also fighting back.",
      },
      {
        "mmdd": "September",
        "publication": "Business Insider",
        "link": "https://www.businessinsider.com/vladimir-putin-deepfake-mit-ai-technology-hao-li-2019-9",
"image": a_fake_interview_with_vladimir_putin_demonstrates_how_convincing_deepfakes_could_be_created_in_realtime_in_just_a_matter_of_years,
"description": "The artist behind the Putin cameo said deepfakes could be \"perfect and virtually undetectable\" within a few years, but we're not quite there yet.",
      },
      {
        "mmdd": "September",
        "publication": "MIT Technology Review",
        "link": "https://www.technologyreview.com/2019/09/18/132961/the-worlds-top-deepfake-artist-wow-this-is-developing-more-rapidly-than-i-thought/",
"image": the_worlds_top_deepfake_artist_wow_this_is_developing_more_rapidly_than_i_thought,
"description": "Hao Li sees deepfake technology as moving quickly toward being indistinguishable from reality.",
      },
      {
        "mmdd": "September",
        "publication": "Spectrum News",
        "link": "https://spectrumnews1.com/ca/la-west/inside-the-issues/2019/09/05/technology-behind-deepfakes-reveals-pros-and-cons#",
"image": usc_professor_at_forefront_of_deepfake_technology,
"description": "One of the experts on deepfakes is based in SoCal.",
      },
      {
        "mmdd": "November",
        "publication": "MIT Technology Review",
        "link": "https://www.technologyreview.com/2019/08/16/133686/the-worlds-top-deepfake-artist-is-wrestling-with-the-monster-he-created/",
"image": the_worlds_top_deepfake_artist_is_wrestling_with_the_monster_he_created,
        "description": "Hao Li has spent his career perfecting digital trickery. Now he’s working to confront the problem of increasingly seamless off-the-shelf deception.",
      },
      {
        "mmdd": "November",
        "publication": "ACM SIGGRAPH Blog",
        "link": "https://blog.siggraph.org/2019/08/real-time-live-stuns-at-siggraph-2019.html/",
"image": realtime_live_stuns_at_siggraph_2019_acm_siggraph_blog,
"description": "A recap of the stunning, interactive presentations during the SIGGRAPH 2019 Real-Time Live! session.",
      },
      {
        "mmdd": "June",
        "publication": "The Verge",
        "link": "https://www.theverge.com/2019/6/27/18715235/deepfake-detection-ai-algorithms-accuracy-will-they-ever-work",
"image": deepfake_detection_algorithms_will_never_be_enough,
"description": "Spotting fakes is just the start of a much bigger battle",
      },
      {
        "mmdd": "June",
        "publication": "Al Jazeera",
        "link": "https://www.aljazeera.com/videos/2019/6/24/fake-videos-pose-a-challenge-for-lawmakers",
"image": fake_videos_pose_a_challenge_for_lawmakers,
"description": "Technology will soon reach a point where even experts will be unable to spot doctored videos.",
      },
      {
        "mmdd": "June",
        "publication": "MIT Technology Review",
        "link": "https://www.technologyreview.com/2019/06/21/134815/a-new-deepfake-detection-tool-should-keep-world-leaders-safefor-now/",
"image": a_new_deepfake_detection_tool_should_keep_world_leaders_safefor_now,
"description": "Donald Trump, Elizabeth Warren, and other presidential hopefuls will be protected against AI doppelgängers thanks to a technique that looks at how they move.",
      },
      {
        "mmdd": "June",
        "publication": "PBS NewsHour",
        "link": "https://www.pbs.org/newshour/show/why-deepfake-videos-are-becoming-more-difficult-to-detect",
"image": why_deepfake_videos_are_becoming_more_difficult_to_detect,
"description": "Sophisticated and inaccurate altered videos known as “deepfakes” are causing alarm in the digital realm.",
      },
    ],
  },
  {
    "year": "2018",
    "links": [
      {
        "mmdd": "September",
        "publication": "Science Friday",
        "link": "https://www.sciencefriday.com/segments/outfitting-avatars-to-cross-the-uncanny-valley/",
"image": outfitting_avatars_to_cross_the_uncanny_valley,
"description": "A virtual reality designer says success is being able to generate photorealistic faces that don't spook.",
      },
      {
        "mmdd": "August",
        "publication": "FXGuide",
        "link": "https://www.fxguide.com/fxfeatured/a-i-at-siggraph-part-2-pinscreen-at-real-time-live/",
"image": ai_at_siggraph_part_2_pinscreen_at_real_time_live,
"description": "Part 2 of our SIGGRAPH AI for VFX with face generation and animation at Pinscreen (for Real Time Live)",
      },
      {
        "mmdd": "April",
        "publication": "CBS News",
        "link": "https://www.cbsnews.com/news/spotting-fake-news-in-a-world-with-manipulated-video/",
"image": spotting_fake_news_in_a_world_with_manipulated_video,
"description": "All it takes is a selfie to manipulate someone's face, and literally put words in their mouth",
      },
      {
        "mmdd": "March",
        "publication": "NY Times",
        "link": "https://www.nytimes.com/2018/03/04/technology/fake-videos-deepfakes.html",
"image": here_come_the_fake_videos_too_published_2018,
"description": "Artificial intelligence video tools make it relatively easy to put one person's face on another person's body with few traces of manipulation. I tried it on myself. What could go wrong?"
      },
      {
        "mmdd": "February",
        "publication": "Forbes",
        "link": "https://www.forbes.com/sites/laurencoleman/2018/02/28/wait-is-fake-news-is-about-to-become-even-more-frightening/#128ec711125d",
"image": wait_is_fake_news_about_to_become_even_more_frightening,
"description": "Here's how video could take things to an entirely new level.",
      },
      {
        "mmdd": "February",
        "publication": "LA Times",
        "link": "https://www.latimes.com/business/technology/la-fi-tn-fake-videos-20180219-story.html",
"image": fake_videos_are_on_the_rise_as_they_become_more_realistic_seeing_shouldnt_always_be_believing,
"description": "All it takes is a single selfie. ",
      },
    ],
  },
  {
    "year": "2017",
    "links": [
      {
        "mmdd": "October",
        "publication": "Wired",
        "link": "https://www.wired.com/story/all-the-face-tracking-tech-behind-apples-animoji/",
"image": all_of_apples_facetracking_tech_behind_the_iphone_xs_animoji,
"description": "The face-tracking tech Apple debuted with the iPhone X has been in the works for decades.",
      },
      {
        "mmdd": "September",
        "publication": "Daily Trojan",
        "link": "https://dailytrojan.com/2017/09/27/professors-research-contributed-iphone-x/",
"image": professors_research_contributed_to_iphone_x_daily_trojan,
"description": "Upon its debut, facial recognition was among the iPhone X’s most talked-about features. USC assistant professor of computer science Hao Li was one of the developers who brought this idea to life.",
      },
      {
        "mmdd": "July",
        "publication": "Radiolab",
        "link": "https://www.radiolab.org/podcast/breaking-news",
"image": breaking_news,
"description": "Today, two new technological tricks that together could invade our past selves and rewrite the rules of credibility. Also, we release something terrible into the world.",
      },
    ],
  },
  {
    "year": "2016",
    "links": [
      {
        "mmdd": "December",
        "publication": "FXGuide",
        "link": "https://www.fxguide.com/fxfeatured/face-me-part-1-photorealistic-facial-texture-from-a-single-still/",
"image": face_me_part_1_photorealistic_facial_texture_from_a_single_still,
"description": "The first in a series that looks at the latest in digital face technology. Starting with a textured 3D face from just one still.",
      },
      {
        "mmdd": "December",
        "publication": "GQ",
        "link": "https://www.gq-magazin.de/auto-technik/article/dieses-neuronale-netz-erschafft-3d-gesichter-aus-unscharfen-fotos",
"image": dieses_neuronale_netz_erschafft_3dgesichter_aus_unscharfen_fotos,
"description": "Intelligente Computersysteme können nicht nur Gesichter erkennen, sondern auch welche erschaffen. Forscher aus Kalifornien lassen etwa ein neuronales Netz dreidimensionale Promi-Abbilder aus einzelnen Fotos generieren. Die Ergebnisse sind geradezu gespenstisch real.",
      },
      {
        "mmdd": "November",
        "publication": "Techcrunch",
        "link": "https://techcrunch.com/2016/11/09/pinscreen-launches-with-high-tech-distractions-for-a-nerve-wracking-election/",
"image": pinscreen_launches_with_hightech_distractions_for_a_nervewracking_election_techcrunch,
"description": "Pinscreen says it has developed a system for generating what can be photorealistic three-dimensional images from a single scan. While the technology's current applications involved distorted faces for caricature, the same technology could be used for exceptionally realistic video.",
      },
      {
        "mmdd": "August",
        "publication": "UploadVR",
        "link": "https://www.uploadvr.com/pinscreen-1-8-million/",
"image": stealth_facetracking_startup_pinscreen_raises_18_million,
"description": "Hao Li is one of the researchers behind an impressive technology demo presented by USC and Oculus Research at last year’s SIGGRAPH. The demo shows a depth sensor attached to a long nose on a Rift development kit to track facial movements. Combined with sensors inside the lining of",
      },
    ],
  },
];


export default PressLinks
