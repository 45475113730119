import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {device} from '../models/device'
import {theme} from '../models/theme'
import { RegularContentDiv, ContentSection, GradientCover, FullPageContentSection, StyledPageDiv, StyledSpacerDiv, HorizontalFeatureSection, FullHeightHorizontalFeatureSection, UnderlineInput, UnderlineSelect, UnderlineSelectOption, WhiteOutlineTextArea } from'../general/GeneralStyles'
import PillButton from '../components/PillButton'

import heroBG from '../images/contact/getInTouch.jpg'
import ContentOverImage from '../components/ContentOverImage'
import ImageBackgroundDiv from '../components/ImageBackgroundDiv'
import { Helmet } from 'react-helmet-async'

export const SpreadSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
`

export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    p {
        margin-block: 0.5em;
    }

    @media ${device.mobile} {
        margin-top: 0;
        margin-bottom: 2rem;
    }
`

export const RightFormSection = styled.form`
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    justify-content: center;
    align-items: start;
    gap: 1.25rem;

    @media ${device.mobile} {
        width: 100%;
    }
`

export const HorizontalFields = styled.div`
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
`

export const TextField = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 40%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
`

export const LocationSection = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: start;
    text-align: left;
`

export const SpacedBottomHeader = styled.h1`
    margin: 4rem 0 4rem 0;
`

export default function Contact() {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [contents, setContents] = useState('');

    return(
        <StyledPageDiv>
            <Helmet>
                <title>Contact</title>
                <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                <meta property="og:title" content="Contact" />
                <meta
                property="og:description"
                content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                />
            </Helmet>
            <StyledSpacerDiv/>
            <RegularContentDiv>
                <FullPageContentSection>
                    <ImageBackgroundDiv source={heroBG} brightness='0.3'>
                        <GradientCover percentCoverage='50%'>
                            <SpreadSection>
                                <h1>
                                    Get In Touch!
                                </h1>
                                <FullHeightHorizontalFeatureSection>
                                    <LeftSection>
                                        <p>
                                            General Info: <a href={'mailto:info@pinscreen.com'}>info@pinscreen.com</a>
                                        </p>
                                        <p>
                                            Join Us: <a href={'mailto:jobs@pinscreen.com'}>jobs@pinscreen.com</a>
                                        </p>
                                    </LeftSection>
                                    <RightFormSection>
                                        <HorizontalFields>
                                            <UnderlineInput onChange={ (event) => { setFirstname(event.target.value) }} type={"text"} name={'fname'} autoComplete={'given-name'} placeholder={'First Name'}/>
                                            <UnderlineInput onChange={ (event) => { setLastname(event.target.value) }} type={"text"} name={'lname'} autoComplete={'family-name'} placeholder={'Last Name'}/>
                                        </HorizontalFields>
                                        <HorizontalFields>
                                            <UnderlineInput onChange={ (event) => { setEmail(event.target.value) }} type={"email"} name={'email'} autoComplete={'email'} placeholder={'Email'}/>
                                        </HorizontalFields>
                                        <HorizontalFields>
                                            <UnderlineSelect onChange={ (event) => { setTitle(event.target.value) }} >
                                                <UnderlineSelectOption value={''} disabled selected hidden>
                                                    How can we help you?
                                                </UnderlineSelectOption>
                                                <UnderlineSelectOption value={'We want to request your VFX services'}>
                                                    We want to request your VFX services
                                                </UnderlineSelectOption>
                                                <UnderlineSelectOption value={'I am interested in a job'}>
                                                    I am interested in a job
                                                </UnderlineSelectOption>
                                            </UnderlineSelect>
                                        </HorizontalFields>
                                        <TextField>
                                            <WhiteOutlineTextArea onChange={ (event) => { setContents(event.target.value) }}/>
                                        </TextField>
                                        <a href={'mailto:' +( title == 'We want to request your VFX services' ? 'info' : 'jobs')+ '@pinscreen.com?cc=' + encodeURIComponent(email) + '&subject='+ title +'&body=' + (encodeURIComponent(contents) + '%0D%0A%0D%0A' + encodeURIComponent(firstname) + '%20' + encodeURIComponent(lastname) )} >
                                            <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Send Message"}/>
                                        </a>
                                    </RightFormSection>
                                </FullHeightHorizontalFeatureSection>
                            </SpreadSection>
                        </GradientCover>
                    </ImageBackgroundDiv>
                </FullPageContentSection>
                <ContentSection>
                    <SpacedBottomHeader>
                        Where to Find Us.
                    </SpacedBottomHeader>
                    <HorizontalFeatureSection>
                        <LocationSection>
                            <h3>PINSCREEN | Los Angeles</h3>
                            <p>
                                Pinscreen HQ<br/>
                                11766 Wilshire Blvd, Suite 840<br/>
                                Los Angeles, CA 90025<br/>
                                United States<br/>
                            </p>
                        </LocationSection>
                        <LocationSection>
                            <h3>PINSCREEN | Abu Dhabi</h3>
                            <p>
                                Pinscreen ME<br/>
                                ADGM<br/>
                                Abu Dhabi<br/>
                                United Arab Emirates<br/>
                            </p>
                        </LocationSection>
                    </HorizontalFeatureSection>
                </ContentSection>
            </RegularContentDiv>
        </StyledPageDiv>
    )
}