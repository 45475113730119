import styled from "styled-components";
import React from 'react'

const StyledHeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 72px;
    margin-top: 16px;
    color: ${props => props.theme.text};
    font-size: 2rem;
    font-weight: bold;
`;

export default function Header(props) {
    return (
        <StyledHeaderDiv>
            {props.title}
        </StyledHeaderDiv>
    )
}
