import React, {useState, useEffect} from 'react'
import { RegularGapContentDiv, ContentSection, StyledPageDiv, StyledSpacerDiv, HorizontalFeatureSection, CoverImage, HorizontalFeatureSectionNoReverse, LogoSection, YouTubePlayer } from '../general/GeneralStyles'
import styled from 'styled-components'
import { device } from '../models/device'
import { theme } from '../models/theme'
import LogoButton from '../components/logoButton'
import PillButton from '../components/PillButton'

import { ReactComponent as Cerence } from "../images/generic/logos/Cerence.svg";
import Colopl from '../images/generic/logos/Colopl.png'
import { ReactComponent as LuxCap } from "../images/generic/logos/LuxCap.svg";
import Softbank from '../images/generic/logos/Softbank.png'


import { TeamMembers } from '../models/team-members'
import { AdvisorBoard } from '../models/advisory-board'

import placeholder from '../images/landing/placeholder_faceswap.png'
import location1 from '../images/company/PinscreenLA.jpg'
import location2 from '../images/company/PinscreenAD.jpg'
import { Helmet } from 'react-helmet-async'

export const GridDiv = styled.div`
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    grid-template-rows: auto 1fr;
    grid-auto-flow: row;
    column-gap: 1.5rem;
    row-gap: 2rem;

    @media ${device.mobile} {
        row-gap: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        grid-template-rows: unset;
        grid-auto-flow: unset;
        align-items: start;
        justify-content: flex-start;
    } */

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    column-gap: 1.5rem;
    row-gap: 2rem;
    height: auto;

    @media ${device.mobile} {
        row-gap: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
    }
  `

export const PersonDiv = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 10rem;
    height: 15rem;
    padding: 1rem;

    h2, p {
        margin-block: 0;
    }

    h2 {
        font-size: 1.25rem;
        font-weight: 300;
        color: ${props => props.theme.accent}
    }

    p {
        font-size: 1rem;
    }

    @media ${device.mobile} {
        height: auto;
        width: 100%;
        padding: 0;
        flex-direction: row;
        justify-content: flex-start;
        h2 {
            font-weight: 400;
            text-align: left;
        }

        p {
            text-align: left;
        }
    }
`

export const AutoPersonDiv = styled(PersonDiv)`
    height: auto;
`

export const LargePersonDiv = styled(PersonDiv)`
    width: 20%;
    height: auto;

    @media ${device.mobile} {
        height: auto;
        width: 100%;
    }
`

export const NameAndTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media ${device.mobile} {
        align-items: start;
    }
`

export const HeaderExtraMarginTop = styled.h1`
    margin-top: 2rem;
`

export const ProfilePic = styled.img`
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: ${props => props.theme.borderRadius};
    margin-bottom: 0.5rem;
    @media ${device.mobile} {
        width: 6rem;
        margin-bottom: 0;
        margin-right: 1rem;
    }

    :hover {
        transform: scale(1.05);
    }

    transition: all ease 0.1s;
`

export const TextSection = styled.div`
    display: block;
`

export const TextSectionLeft = styled.div`
    display: block;
    text-align: left;
    width: 50%;
    @media ${device.mobile} {
        width: 100%;
    }
`

export const FloatingVideoDiv = styled.div`
    float: right;
    width: 40%;
    padding: 0 2rem 1rem 2rem;
    @media ${device.mobile} {
        float: unset;
        width: 100%;
        padding: 0;
    }
`

export default function AboutUsCompany() {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })


    useEffect(() => {
        // for resize handling
        function handleResize() {
            setDimensions({
              height: window.innerHeight ,
              width: window.innerWidth 
            });
        }


        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return(
        <StyledPageDiv>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                <meta property="og:title" content="About Us" />
                <meta
                property="og:description"
                content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                />
            </Helmet>
            <StyledSpacerDiv/>
            <RegularGapContentDiv>
                <ContentSection>
                    <TextSection>
                        <h1>
                            About Us
                        </h1>
                        <TextSection>
                            <FloatingVideoDiv>
                                <YouTubePlayer aspectRatio={'16/9'} src="https://www.youtube.com/embed/DoZFfUob82U?si=mzCaN5FphLv0N9XT"/>
                                <a href='https://www.youtube.com/watch?v=DoZFfUob82U' target="_blank">
                                    <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Watch Documentary"}/>
                                </a>
                            </FloatingVideoDiv>
                            <p>
                                Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for
                                film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE).
                            </p>
                            <p>
                                The company offers end-to-end solutions for AI lip sync, face reenactment, face swaps, de-aging, and avatar digitization.
                                Pinscreen works with some of the most prolific film makers and production studios, and its customers include
                                Netflix, Amazon Studios, Miramax, Warner Bros, Apple, Google, Adobe, DARPA.
                            </p>
                            <p>
                                Among its accolades, in 2019, Pinscreen developed the first real-time deepfake technology which was presented at
                                the World Economic Forum in Davos, and SIGGRAPH 2020. The company is also the first to demonstrate AI lip sync
                                for a complete feature film, and the ability to process over thousands of 4K HDR movie shots within a year. 
                                Pinscreen is part of the NVIDIA Inception Program and won the Epic MegaGrants in 2020.
                            </p>
                            <p>
                                Pinscreen was founded in 2015 by Prof. <a href='http://www.hao-li.com/'>Hao Li</a>, MIT TR35 winner and Professor of Computer Vision at MBZUAI. He is known for his contributions in
                                Apple’s Animoji technology, the reenactment of Paul Walker in Furious 7, and actively innovates in the space of neural rendering, deepfakes, and digital
                                humans.
                            </p>
                        </TextSection>
                    </TextSection>
                </ContentSection>
                <ContentSection>
                    <h1>
                        Our Team
                    </h1>
                    <GridDiv>
                        { TeamMembers.map( (member, index) => 
                        <PersonDiv href={member.website}>
                            <ProfilePic src={member.profilePic}/>
                            <NameAndTitle>
                                <h2>
                                    {member.name}
                                </h2>
                                <p>
                                    {member.title}
                                </p>
                            </NameAndTitle>
                        </PersonDiv>
                        )}
                    </GridDiv>
                </ContentSection>
                <ContentSection>
                    <HeaderExtraMarginTop>
                        Advisory Board
                    </HeaderExtraMarginTop>
                    <GridDiv>
                        { AdvisorBoard.map( (member, index) => 
                        <AutoPersonDiv href={member.website}>
                            <ProfilePic src={member.profilePic}/>
                            <NameAndTitle>
                                <h2>
                                    {member.name}
                                </h2>
                                <p>
                                    {member.title}
                                </p>
                            </NameAndTitle>
                        </AutoPersonDiv>
                        )}
                    </GridDiv>
                </ContentSection>
                <ContentSection>
                    <HeaderExtraMarginTop>
                        Investors
                    </HeaderExtraMarginTop>
                    {
                        dimensions.width <= 768 ?
                        <LogoSection logoGap='2rem'>
                            <a href='https://www.luxcapital.com/' target='_blank'>
                                <LogoButton iconHeight={'4rem'}>
                                    <LuxCap/>
                                </LogoButton>
                            </a>
                            <a href='https://www.coloplnext.co.jp/?lang=en' target='_blank'>
                                <LogoButton iconHeight={'6rem'}>
                                    <img src={Colopl}/>
                                </LogoButton>
                            </a>
                            <a href='https://sbvacorp.com/en' target='_blank'>
                                <LogoButton iconHeight={'3.5rem'}>
                                    <img src={Softbank}/>
                                </LogoButton>
                            </a>
                            <a href='https://www.cerence.com/' target='_blank'>
                                <LogoButton iconHeight={'2.5rem'}>
                                    <Cerence/>
                                </LogoButton>
                            </a>
                        </LogoSection>
                        :
                        <LogoSection logoGap='4rem'>
                            <a href='https://www.luxcapital.com/' target='_blank'>
                                <LogoButton iconHeight={'6rem'}>
                                    <LuxCap/>
                                </LogoButton>
                            </a>
                            <a href='https://www.coloplnext.co.jp/?lang=en' target='_blank'>
                                <LogoButton iconHeight={'7rem'}>
                                    <img src={Colopl}/>
                                </LogoButton>
                            </a>
                            <a href='https://sbvacorp.com/en' target='_blank'>
                                <LogoButton iconHeight={'5rem'}>
                                    <img src={Softbank}/>
                                </LogoButton>
                            </a>
                            <a href='https://www.cerence.com/' target='_blank'>
                                <LogoButton iconHeight={'4rem'}>
                                    <Cerence/>
                                </LogoButton>
                            </a>
                        </LogoSection>
                    }

                </ContentSection>
                <ContentSection>
                    <h1>
                        Locations
                    </h1>
                    <HorizontalFeatureSection>
                        <TextSectionLeft>
                            <h3>
                                PINSCREEN | Los Angeles
                            </h3>
                            <p>
                                Since 2015, Pinscreen has been headquartered in West Los Angeles, in the heart of
                                Hollywood and Silicon Beach. It has attracted renowned AI researchers, engineers, and
                                game designers from top Universities (USC, UC Berkeley, MIT) as well as world-class
                                visual effects artists from prestigious art programs (Gnomon).
                            </p>
                            <p>
                                We offer mixed in-office and remote work options, and our facilities provide top of the
                                range compute resources and GPUs for challenging AI VFX tasks. Pinscreen’s location
                                in LA facilitates seamless work with all the major film studios, streaming companies, and
                                the greatest film makers.
                            </p>
                        </TextSectionLeft>
                        <CoverImage aspectRatio={'12/9'} src={location1} rounded setWidth='50%'/>
                    </HorizontalFeatureSection>
                    <HorizontalFeatureSectionNoReverse>
                        <CoverImage aspectRatio={'12/9'} src={location2} rounded setWidth='50%'/>
                        <TextSectionLeft>
                            <h3>
                                PINSCREEN | Abu Dhabi
                            </h3>
                            <p>
                                In 2024, Pinscreen opened its first international branch in Abu Dhabi (UAE) with new
                                investment from the Middle East. With its proximity to Bollywood (India) and East
                                Asia (China, Hong Kong, Japan, and South Korea), Pinscreen expanded its
                                operations beyond Hollywood and is also providing full services to regional projects.
                            </p>
                            <p>
                                The Abu Dhabi office also provides additional R&D development recruiting top AI
                                talents from the leading AI school, Mohamed bin Zayed University of Artificial
                                Intelligence (MBZUAI), at which our founder, Hao Li, is Professor at the Computer
                                Vision Department and Director of its Metaverse Center.
                            </p>
                        </TextSectionLeft>
                    </HorizontalFeatureSectionNoReverse>
                </ContentSection>
            </RegularGapContentDiv>
        </StyledPageDiv>
    )
}