import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import { device } from '../models/device';
import MinimalVideo from "./MinimalVideo";
import { FullPageMarginlessContentSection } from "../general/GeneralStyles";
import { useMemo } from "react";

export const StyleSlide = styled(SwiperSlide)`
    position: relative;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
`

export const StyleSlideHidder = styled.div`
    width: 100vw;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
`

export const StyledSlideVidHorz = styled.video`
    object-position: center center;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
`

export const StyledContainerDiv = styled.div`
    position: absolute;
    pointer-events: none;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`

export const StyledContentDivTop = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: ${props => props.theme.textLight};
    padding: 0 0 calc(20px + 0.25rem) 0;
    width: 100%;
`



export default function HorizontalVideoSlide(props) {
    const [video, setVideo] = useState(null);

    return (
        <StyleSlideHidder>
            <MinimalVideo className="slider-bg" source={props.source} onReady={setVideo} aspectRatio={props.aspectRatio} playing={props.playing} looping={false} onEnded={() => {console.log(`video ${props.source} has ended!`); props.onEnded();}} previewImage={props.previewImage}/>
            <StyledContainerDiv className="content">
                <StyledContentDivTop>
                    {props.children}
                </StyledContentDivTop>
            </StyledContainerDiv>
        </StyleSlideHidder>
    );
}
