import styled from "styled-components";
import React, { useEffect, useState} from 'react'

export const OpacitySpan= styled.span.attrs( props => ({
    style: {
        animationName: props.animationName,
        animationDelay:  props.delay + 's'
    }
}))`
    opacity: 0;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
`

export default function AnimatedTypedText(props) {
    const [childrenString, setChildrenString] = useState([...props.children.substring(0, props.children.length)]);
    const [animationReset, setAnimationReset] = useState(false);
    const [animationName, setAnimationName] = useState("fadeIn");

    useEffect(() => {
        setAnimationName("");
        setAnimationReset(!animationReset);
        setChildrenString([]);
    }, [props.children]);

    useEffect(() => {
        setAnimationName("fadeIn");
        setChildrenString([...props.children.substring(0, props.children.length)]);
    }, [animationReset]);

    return(
        <>
            {
                childrenString.map((char, index) =>
                    <OpacitySpan delay={(index / childrenString.length) * 0.02} animationName={animationName} >
                        { childrenString[index] }
                    </OpacitySpan>
            )}
        </>
    )
}