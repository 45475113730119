import styled, { css } from "styled-components";
import React, {useState, useEffect} from 'react'
import { device } from '../models/device';
import { useSpring, animated } from 'react-spring';
import { Fragment } from "react";

export const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: ${props => props.iconHeight};
    text-align: center;
    margin: 0;
    background: none;
    border-radius: none;
    padding-inline: 0;
    padding-block: 0;
    cursor: pointer;
    :hover {
        background: none;

        & svg {
        fill: ${props => props.theme.textLight};
        }

        img {
            filter: brightness(100000%);
        }
    }
    & svg {
        width: auto;
        height: ${props => props.iconHeight};
        fill: ${props => props.theme.accentVariant };
    }
    img {
        width: auto;
        height: ${props => props.iconHeight};
    }
    transition: all ease 0.1s;
`;

export const StyledIcon = styled.div.attrs(props => ({
    className: props.Icon || 'img',
  }))`

`;

function buttonContent(props, setIsHovered) {
    return(
        <StyledButton type="button" iconHeight={props.iconHeight} value={props.value} onChange={props.handleChange} onClick={props.handleClick} onMouseEnter={ () => {setIsHovered(true);}} onMouseLeave={ () => {setIsHovered(false);}}>
        {props.children}
        </StyledButton>
    );
}

export default function LogoButton(props) {
    const [isHovered, setIsHovered] = React.useState(false);
    const hoverAnimStyle = useSpring({
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        config: { mass: 1, tension: 500, friction: 80 }
        });
    useEffect(() => {
        // button animation on hover using react spring
        
      }, []);
    return(
        <Fragment>
            {buttonContent(props, setIsHovered)}
        </Fragment>
    )
}