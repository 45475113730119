export const theme = {
    primary: '#f5f5f5',
    secondary: '#080707',
    text: '#080707',
    textLight: '#f5f5f5',
    textHover: '#d5d5d5',
    button: '#212225',
    buttonHover: '#333333',
    buttonTransparent: '#00000094',
    buttonHoverTransparent: '#33333394',
    panel: '#f5f5f5',
    panelTransparent: '#2e2e35b4',
    panelTransparentLight: '#bcbcc77c',
    panelTransparentDark: '#00000094',
    panelDark: '#030303',
    ShadeCover: '#0000004f',
    ShadeCoverDark: '#00000098',
    accent: '#f27200',
    accentVariant: '#6e9d82',
    accentVariantDark: '#456151',
    lightShade: '#dcdce63f',
    borderRadius: '1rem',
    largeSpace: '64px',
    mediumSpace: '32px',
    smallSpace: '16px'
  }