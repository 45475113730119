import React, {useState} from 'react'
import { RegularContentDiv, ContentSection, StyledPageDiv, StyledSpacerDiv } from '../general/GeneralStyles'
import styled from 'styled-components'

import { Projects } from '../models/projects'
import { device } from "../models/device";

import UnderlineRadio from '../components/UnderlineRadio'
import { Helmet } from 'react-helmet-async';


export const GridDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr;
    grid-auto-flow: row;
    column-gap: 1.5rem;
    row-gap: 2rem;

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        grid-template-rows: unset;
        grid-auto-flow: unset;
        align-items: start;
        justify-content: flex-start;
    }
  `

export const ProjectDiv = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;

    h2, p {
        font-size: 1.25rem;
        margin-block: 1rem;
    }

    @media ${device.mobile} {
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        h2, p {
            text-align: left;
        }
    }
`

export const ProjectPreview = styled.img`
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: ${props => props.theme.borderRadius};
    @media ${device.mobile} {
        height: auto;
        width: 40%;
    }

    :hover {
            transform: scale(1.05);
        }
        transition: all ease 0.1s;
`

export const ExtraMarginHeader = styled.h1`
    margin-block-end: 4rem;
`

export default function VFXProjects() {
    const [activeProjects, setActiveProjects] = useState(Projects);



    return(
        <StyledPageDiv>
            <Helmet>
                <title>Projects</title>
                <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                <meta property="og:title" content="Projects" />
                <meta
                property="og:description"
                content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                />
            </Helmet>
            <StyledSpacerDiv/>
            <RegularContentDiv>
                <ContentSection>
                    <ExtraMarginHeader>
                        Projects
                    </ExtraMarginHeader>
                    <UnderlineRadio 
                    optionList={[{text: 'ALL', tag: 'all'}, {text: 'Face Reenactment / AI Lip Sync', tag: 'lip_sync'}, {text: 'Face Swap / De-Aging', tag: 'face_swap'}, {text: 'Voice Cloning', tag: 'voice_clone'}]}
                    handleSubmit={ (targetTag) => {
                        console.log(targetTag);
                        switch (targetTag) {
                            case 'all':
                                setActiveProjects(Projects);
                                break;
                            case 'lip_sync':
                                setActiveProjects(Projects.filter((project) =>  project.tags.find( (tag) => tag == targetTag)));
                                break;
                            case 'face_swap':
                                setActiveProjects(Projects.filter((project) =>  project.tags.find( (tag) => tag == targetTag)));
                                break;
                            case 'voice_clone':
                                setActiveProjects(Projects.filter((project) =>  project.tags.find( (tag) => tag == targetTag)));
                                break;
                            default:
                                setActiveProjects(Projects);
                                break;
                        }
                    } }
                    >
                        <GridDiv>
                            { activeProjects.map( (project, index) => 
                            <ProjectDiv href={'vfx-projects/details/' + project.url}>
                                <ProjectPreview src={project.previewPic}/>
                                <h2>
                                    {project.title + ' (' + project.date + ')'}
                                </h2>
                            </ProjectDiv>
                            )}
                        </GridDiv>
                    </UnderlineRadio>
                </ContentSection>
            </RegularContentDiv>
        </StyledPageDiv>
    )
}