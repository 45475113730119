import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { RegularContentDiv, ContentSection, StyledPageDiv, StyledSpacerDiv, HorizontalFeatureSection, YouTubePlayer } from '../general/GeneralStyles'
import styled from 'styled-components'

import 'swiper/css/bundle';

import { theme } from '../models/theme'
import {device} from '../models/device'

import { Projects } from '../models/projects'
import TextButton from '../components/TextButton';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export const BackButton = styled(Link)`
    top: 1rem;
    left: 5%;
    position: absolute;
    width: auto;
    height: auto;
    font-size: 2rem;
    color: ${props => props.theme.textLight};
    background: none;
    transition: all ease 0.2s;
    font-family: 'swiper-icons';
    font-variant: initial;

    :hover {
        transform: scale(1.05);
    }
`

export const ProjectHero = styled.img`
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: ${props => props.theme.borderRadius};
`

export const HalfSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const CreditsDiv = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
    column-gap: 2rem;
    @media ${device.mobile} {
        margin-bottom: 2rem;
    }
`

export const CreditDiv = styled.div`
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        font-weight: 400;
        font-size: 1.25rem;
    }
    h2, p {
        margin-block: 0;
        width: auto;
        display: inline;
    }

    @media ${device.mobile} {
        width: auto;
    }
`

export const CreditsTitle = styled.h2`
    width: auto;
    font-weight: 400;
`

export default function VFXProjectsDetails() {
    const { projectName } = useParams();
    const [activeProject, setActiveProject] = useState(undefined)

    useEffect(() => {
        console.log(projectName);
        setActiveProject(Projects.find(project => project.url === projectName ));
      }, [projectName]);

    return(
        <StyledPageDiv>
            <StyledSpacerDiv/>
            <RegularContentDiv>
                {
                    activeProject != undefined ?
                        <ContentSection>
                            <Helmet>
                                <title>{activeProject.title}</title>
                                <meta name="description" content={activeProject.description} />
                                <meta property="og:title" content= {activeProject.title} />
                                <meta
                                property="og:description"
                                content={activeProject.description}
                                />
                                <meta property='og:image' content={activeProject.previewPic}/>
                            </Helmet>
                            <BackButton to={'/vfx-projects'}>prev</BackButton>
                            <h1>
                                {activeProject.title}
                            </h1>
                            <ProjectHero src={activeProject.bannerPic}/>
                            <p>
                                {activeProject.description}
                            </p>
                            <HorizontalFeatureSection>
                                {
                                    activeProject.youtubeLink ?
                                    <HalfSection>
                                        <YouTubePlayer aspectRatio={'16/9'} src={activeProject.youtubeLink}/>
                                    </HalfSection>
                                    :
                                    <></>
                                }
                                <HalfSection>
                                    {
                                        activeProject.creditsTitle ? 
                                        <>
                                            <CreditsTitle>{activeProject.creditsTitle.name + " - " + activeProject.creditsTitle.title}</CreditsTitle>
                                        </>
                                        :
                                        <></>
                                    }
                                    <CreditsDiv>
                                        {
                                            activeProject.credits.map( (credit) => 
                                            <CreditDiv>
                                                <h2>{credit.title}</h2>
                                                {credit.name ?
                                                    <p>{credit.name}</p>
                                                :
                                                    <></>
                                                }
                                            </CreditDiv>
                                        )}
                                    </CreditsDiv>
                                </HalfSection>
                            </HorizontalFeatureSection>
                        </ContentSection> 
                :
                    <div></div>
                }
            </RegularContentDiv>
        </StyledPageDiv>
    )
}