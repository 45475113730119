import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import {device} from '../models/device'
import {theme} from '../models/theme'
import { RegularPaddinglessContentDiv, ContentSection, FullPageContentSection, YouTubePlayer, FullWidthContentSection, StyledPageDiv, StyledSpacerDiv, HorizontalFeatureSection, VerticalFeatureSection, UnderlineInput, UnderlineSelect, UnderlineSelectOption, WhiteOutlineTextArea } from'../general/GeneralStyles'
import PillButton from '../components/PillButton'

import heroVid from '../images/ethics/EthicsPrivacy.mp4'
import heroVidThumb from '../images/ethics/EthicsPrivacy.jpg'
import VideoBackgroundDiv from '../components/VideoBackgroundDiv'

import icon1 from '../images/ethics/icon1.png'
import icon2 from '../images/ethics/icon2.png'
import icon3 from '../images/ethics/icon3.png'
import icon4 from '../images/ethics/icon4.png'
import { Helmet } from 'react-helmet-async'

export const SpreadSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
`

export const SpreadSectionCentered = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
`

export const BottomLeftAlignedSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: start;
`

export const LeftSection = styled.div`
    margin-top: 30vh;
    display: block;
    flex-direction: column;
    width: auto;
    height: 100%;
    justify-content: center;
    align-items: start;

    h3, p {
        text-align: left;
        color: ${props => props.theme.textLight};
    }

    @media ${device.mobile} {
        margin-top: 4rem;

        h3, p {
            text-align: center;
        }
    }
`

export const ListSection = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100%;
    justify-content: center;
    align-items: start;
    gap: 2rem;

    @media ${device.mobile} {

    }
`

export const HorizontalFields = styled.div`
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
`

export const TextField = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 40%;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
`

export const LocationSection = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: start;
    text-align: left;
`

export const FeatureRow = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    gap: 2rem;

    p {
        margin-block: 0;
    }

    @media ${device.mobile} {
        text-align: center;
        flex-direction: column;
    }
`

export const FeatureImage = styled.img`
    height: 3rem;
    width: auto;
`

export const SpacedBottomHeader = styled.p`
    margin: 2rem 0 2rem 0;
`

export const StyledButton = styled(PillButton)`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const StyledA = styled.a`
    margin: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export default function EthicsSafety() {

    return(
        <StyledPageDiv>
            <Helmet>
                <title>Ethics & Safety</title>
                <meta name="description" content="Pinscreen is committed to responsible implementation of its technology to prevent malicious use, such as harassment, disinformation, fraud, and invasion of privacy, which is reflected in our company policy and products." key="desc" />
                <meta property="og:title" content="Ethics & Safety" />
                <meta
                property="og:description"
                content="Pinscreen is committed to responsible implementation of its technology to prevent malicious use, such as harassment, disinformation, fraud, and invasion of privacy, which is reflected in our company policy and products."
                />
            </Helmet>
            <StyledSpacerDiv/>
            <RegularPaddinglessContentDiv>
                <FullWidthContentSection>
                    <VideoBackgroundDiv source={heroVid} brightness='50' playing={true} previewImage={heroVidThumb}>
                        <SpreadSection>
                            <h1>
                                Ethics & Safety
                            </h1>
                            <BottomLeftAlignedSection>
                                <LeftSection>
                                    <h3>
                                        Committed to Safe and Responsible use of Generative AI
                                    </h3>
                                    <p>
                                    Pinscreen's AI VFX Services can generate video content of people doing things they have never done and saying things they have never said. The results are nearly indistinguishable from reality and our generative Al technology continues to improve. Many of our results are undetectable by state-of-the-art deepfake detectors. To prevent potential misuse of such technology, Pinscreen is committed to responsible implementation of its technology to prevent malicious use, such as harassment, disinformation, fraud, and invasion of privacy, which is reflected in our company policy and products:
                                    </p>
                                    <ListSection>
                                        <FeatureRow>
                                            <FeatureImage src={icon1}/>
                                            <p>
                                                Pinscreen does not develop products that can be harmful or offensive to the general public.
                                            </p>
                                        </FeatureRow>
                                        <FeatureRow>
                                            <FeatureImage src={icon2}/>
                                            <p>
                                                We do not allow end-users to generate disinformation and all content created by Pinscreen's technology is screened carefully.
                                            </p>
                                        </FeatureRow>
                                        <FeatureRow>
                                            <FeatureImage src={icon3}/>
                                            <p>
                                                Pinscreen is committed to actively raising awareness about the dangers of AI-synthesized media and educating the public on best practices around disruptive technologies.
                                            </p>
                                        </FeatureRow>
                                        <FeatureRow>
                                            <FeatureImage src={icon4}/>
                                            <p>
                                                We regularly publish our research in top peer-reviewed scientific venues to make our knowledge accessible to the security community however we do not release source code to prevent potential misuse of the technology.
                                            </p>
                                        </FeatureRow>
                                    </ListSection>
                                </LeftSection>
                            </BottomLeftAlignedSection>
                        </SpreadSection>
                    </VideoBackgroundDiv>
                </FullWidthContentSection>
                <ContentSection>
                    <SpacedBottomHeader>
                        <a href='http://www.hao-li.com/'>Hao Li</a> explores the known and potential implications of this technology on society, and how we need to react at the World Economic Forum Annual Meeting 2020 in Davos.
                    </SpacedBottomHeader>
                    <SpreadSectionCentered>
                        <YouTubePlayer aspectRatio={'16/9'} src={'https://www.youtube.com/embed/vqFsaKhGdDY?si=VHNILrIvXHXdYWya'}/>
                        <StyledA href={'https://youtu.be/vqFsaKhGdDY?si=OCLvw7-Y_ell2zs7'} target='_blank'>
                            <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"Watch Talk"}/>
                        </StyledA>
                    </SpreadSectionCentered>
                </ContentSection>
            </RegularPaddinglessContentDiv>
        </StyledPageDiv>
    )
}