import React, {useState} from 'react'
import styled from 'styled-components'
import { device } from '../models/device'
import { theme } from '../models/theme'
import { RegularContentDiv, RegularPaddinglessContentDiv, ContentSection, FullPageContentSection, FullPageMarginlessContentSectionMobile, StyledPageDiv, StyledSpacerDiv } from '../general/GeneralStyles'
import placeholder from '../images/landing/placeholder_faceswap.png'

import PillButton from '../components/PillButton'

import background from '../images/developer_sdk/AvatarNeo_SDK.mp4';
import backgroundThumb from '../images/developer_sdk/AvatarNeo_SDK.jpg'
import ContentOverVideo from '../components/ContentOverVideo'
import { Helmet } from 'react-helmet-async'
import YoutubePopup from '../components/YoutubePopup'


export const FullTopFlex = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
`

export const TitleOverDiv = styled.div`
top:0;
    position: absolute;

    h1 {
        position: relative;
       font-size: 2rem;
    }

    @media ${device.mobile} {
        position: relative;
    }
`

export const BottomAlignedSection = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media ${device.mobile} {
        width: 90%;
    }
`

export const BottomAlignedMarginSection = styled.div`
    height: 100%;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    z-index: 10;
    background: none;
`

export const BottomRowSection = styled.div`
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0 2rem 0;
    padding: 1rem;

    background: ${props => props.theme.panelTransparentDark};
    backdrop-filter: blur(10px);
    border-radius: ${props => props.theme.borderRadius};

    h1, h2, p {
        text-align: center;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-block: 0.5rem;
        @media ${device.mobile} {
            text-shadow: ${props => props.theme.panelDark} 0 0 8rem;
        }
    }

    h2 {
        color: ${props => props.theme.accent};
        font-weight: 400;
    }

    p {
        @media ${device.mobile} {
            font-size: 1rem;
        }
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: center;

        h1, h2, p {
            text-align: center;
        }
    }
`

const HorizFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
`

// export const ImageBehindDiv = styled.div`
//     position: absolute;
//     width: 100%;
//     height: ;
//     z-index: 0;
// `

// export const ImageBehind = styled.img`
//     position: relative;
//     width: 100%;
//     height: calc(100vh - 86);
//     aspect-ratio: ${props => props.aspectRatio};
//     object-fit: cover;
// `

export default function AvatarDeveloper() {
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    return(
        <StyledPageDiv>
            <Helmet>
                <title>Developer SDK</title>
                <meta name="description" content="Avatar Neo SDK allows developers to easily incorporate high-fidelity 3D avatar generation from photos into 3D apps, games, and AR/VR experiences." key="desc" />
                <meta property="og:title" content="Creator App" />
                <meta
                property="og:description"
                content="Avatar Neo SDK allows developers to easily incorporate high-fidelity 3D avatar generation from photos into 3D apps, games, and AR/VR experiences."
                />
            </Helmet>
            <YoutubePopup youtubeLink='https://www.youtube.com/embed/SUYcTjre0Vg?si=WGvQsd-R61mnpxpM' onClose={() => {setIsOpenPopup(false)}} isOpen={isOpenPopup}/>
            <StyledSpacerDiv/>
            <RegularPaddinglessContentDiv>
                <FullPageMarginlessContentSectionMobile>
                    <FullTopFlex>
                        <ContentOverVideo source={background} videoBrightness='50' previewImage={backgroundThumb}>
                            <TitleOverDiv>
                                <h1>
                                    Developer SDK
                                </h1>
                            </TitleOverDiv>
                            <BottomAlignedSection>
                                    <BottomRowSection>
                                        <p>
                                        Avatar Neo SDK allows developers to easily incorporate high-fidelity 3D avatar generation from photos into 3D apps, games, and AR/VR experiences. The application communicates with our servers and generates a 3D avatar from the uploaded photo.</p>
                                        <p>Start by choosing a platform such as Unreal or Unity, and download the corresponding plugin.</p>
                                    </BottomRowSection>
                                    <HorizFlex>
                                    <a href='https://avatarneo.com/sdk-download/' target="_blank">
                                        <PillButton baseBackground={theme.buttonTransparent} baseColor={theme.textLight} hoverBackground={theme.buttonHoverTransparent} hoverColor={theme.textLight} value={"Learn More"}/>
                                    </a>
                                    <PillButton baseBackground={theme.buttonTransparent} baseColor={theme.textLight} hoverBackground={theme.buttonHoverTransparent} hoverColor={theme.textLight} value={"Watch Video"} handleClick={() => {setIsOpenPopup(true)}}/>
                                    </HorizFlex>
                            </BottomAlignedSection>
                        </ContentOverVideo>
                    </FullTopFlex>
                </FullPageMarginlessContentSectionMobile>
            </RegularPaddinglessContentDiv>
        </StyledPageDiv>
    )
}