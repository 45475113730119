import { createGlobalStyle } from 'styled-components';
import { device } from './models/device';
 
const GlobalStyle = createGlobalStyle`

    body {
        margin: 0;
        padding: 0;
        background: ${props => props.theme.panelDark};
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        > li {
            margin: 0;
            padding: 0;
        }
    }

    button {
        margin: 0;
        background: ${props => props.theme.secondary};
        border: none;

        :hover {
            background: ${props => props.theme.secondaryVariant};
        }
    }

    h1 {
        font-weight: 400;
        font-size: 2rem;
        color: ${props => props.theme.textLight};
        margin-block-start: 1rem;
        margin-block-end: 1rem;
    }

    h2 {
        font-weight: 200;
        font-size: 1.5rem;
        color: ${props => props.theme.textLight};
        
    }

    h3 {
        font-weight: 400;
        font-size: 1.5rem;
        color: ${props => props.theme.accent};
        
    }

    p {
        font-weight: 200;
        font-size: 1.25rem;
        color: ${props => props.theme.textLight};
        margin-block-start: 2em;
        margin-block-end: 2em;
    }

    a {
        font-weight: 200;
        font-size: 1.25rem;
        color: ${props => props.theme.accent};
        text-decoration: none;

    :hover {
        color: ${props => props.theme.textLight};
    }

    transition: all ease 0.1s;
    }

    a:hover, a:focus, a:active {
        text-decoration: none;
    }

    .md {
        padding: 20px;      
    }

    .md h1 {
        font-size: 1.75rem;
        padding-bottom: 0.3rem;
        border-bottom: 1px solid ${props => props.theme.panel};      
    }

    .md h2 {
        font-size: 1.5rem;
        padding-bottom: 0.3rem;
        border-bottom: 1px solid ${props => props.theme.secondaryVariant};      
    }

    .md h3 {
        font-size: 1.25rem;
    }

    .md h4 {
        font-size: 1.12rem;
    }

    .md h5 {
        font-size: 1rem;
    }

    .md p {
        font-size: 1rem;    
        line-height: 1.5
    }

    .md code {
        padding: 2px 4px;
        font-size: 90%;
        background-color: ${props => props.theme.panel};
        border-radius: 4px;
    }

    .md pre {
        margin-bottom: 16px;
        font-size: 15px;
        line-height: 1.6rem;
        overflow-x: auto;
        border-radius: 2px;
        background-color: ${props => props.theme.panel};
        border: 1px solid ${props => props.theme.panelVariant};
        padding: 8px 12px;
        margin: 0 0 8px;
    }

    .md pre code {
        white-space: pre;
        word-wrap: normal;
        overflow-wrap: normal;
        background-color: ${props => props.theme.panel};
        > a {
            color: ${props => props.theme.accent};
            font-family: inherit;
        }
    }

    .md ul, .md ol {
        padding: 0;
        margin: 0 0 16px;
        list-style-type: disc;
    }

    .md li {
        margin-left: 28px;
        padding-left: 0;
        line-height: 1.75rem;
    }

    /* animations */

    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    @keyframes blurIn {
        0% { filter: blur(10px); }
        100% { filter: blur(0px);  }
    }

    @keyframes fadeAndBlurIn {
        0% { opacity: 0;  filter: blur(20px); }
        100% { opacity: 1; filter: blur(0px); }
    }


`;
 
export default GlobalStyle;