import React from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import { device } from '../models/device';

export const StyleSlide = styled(SwiperSlide)`
    position: relative;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
`

export const StyleSlideHidder = styled.div`
    width: 100vw;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
`

export const StyledSlideImageHorz = styled.img`
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    object-position: center center;
    width: 100%;
    height: auto;
    object-fit: cover;
    z-index: 0;
    border: none;
`

export const StyledContainerDiv = styled.div`
    position: absolute;
    pointer-events: none;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`

export const StyledContentDivTop = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: ${props => props.theme.textLight};
    padding: 0 0 4rem 0;
    width: 100%;
    z-index: 50;
`



export default function HorizontalAspectRatioSlide(props) {
    return (
        <StyleSlideHidder>
            <StyledSlideImageHorz className="slider-bg" src={props.source} aspectRatio={props.aspectRatio}/>
            <StyledContainerDiv className="content">
                <StyledContentDivTop>
                    {props.children}
                </StyledContentDivTop>
            </StyledContainerDiv>
        </StyleSlideHidder>
    );
}
