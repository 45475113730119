import styled from "styled-components";
import React from 'react'
import { device } from "../models/device";
import { theme } from '../models/theme'
import { Link } from 'react-router-dom';

import IconButton from './IconButton'
import TextButton from "./TextButton";

import {ReactComponent as LinkedinIcon} from '../images/footer/linkedin.svg'
import {ReactComponent as InstagramIcon} from '../images/footer/instagram.svg'
import {ReactComponent as FacebookIcon} from '../images/footer/facebook.svg'
import {ReactComponent as TwitterIcon} from '../images/footer/twitter.svg'
import {ReactComponent as YoutubeIcon} from '../images/footer/youtube.svg'


const StyledFooterDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    @media ${device.mobile} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const StyledList = styled.ul`
    display: flex;
    justify-content: center;
    li {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        @media ${device.mobile} {
            font-size: .8rem;
        }
        a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: auto;
        }
    }

    gap: ${props => props.theme.mediumSpace};

    @media ${device.mobile} {
        gap: 6px;
    }
`;

const StyledSocialsList = styled.ul`
    display: flex;
    justify-content: center;
    gap: ${props => props.theme.smallSpace};
    li {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        @media ${device.mobile} {
            font-size: .8rem;
            padding: 0 10px;
        }
    }
    @media ${device.mobile} {
        margin: 1rem 0 1rem 0;
    }
`;

const StyledExpanderDiv = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 96px;
    width: 100%;
    padding: 0 37.5px 0 37.5px;
    background: ${props => props.theme.accentVariant};

    @media ${device.mobile} {
        padding: 0;
    }
`;

export default function Footer() {
    return(
        <StyledExpanderDiv>
            <StyledFooterDiv>
                <StyledList>
                    <li>
                        <a href='https://www.pinscreen.com/'>
                            <TextButton mobileShrink textColor={ theme.textLight }>Pinscreen &copy; 2024</TextButton>
                        </a>
                    </li>
                    <li>
                        <a href='/terms-of-use'>
                            <TextButton mobileShrink textColor={ theme.textLight }>Terms</TextButton>
                        </a>
                    </li>
                    <li>
                        <a href='/privacy-policy'>
                        <TextButton mobileShrink textColor={ theme.textLight }>Privacy</TextButton>
                        </a>
                    </li>
                    <li>
                        <a href='mailto:support@avatarneo.com'>
                        <TextButton mobileShrink textColor={ theme.textLight }>Contact</TextButton>
                        </a>
                    </li>
                    <li>
                        <Link to='/jobs'>
                            <TextButton mobileShrink textColor={ theme.textLight }>Careers</TextButton>
                        </Link>
                    </li>
                </StyledList>
                <StyledSocialsList>
                    <li>
                        <Link to={'https://www.linkedin.com/company/pinscreen'}>
                            <IconButton light>
                                <LinkedinIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://www.instagram.com/pinscreeninc/'}>
                            <IconButton light>
                                <InstagramIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://www.youtube.com/@pinscreenchannel'}>
                            <IconButton light>
                                <YoutubeIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://www.facebook.com/pinscreeninc/'}>
                            <IconButton light>
                                <FacebookIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    <li>
                        <Link to={'https://twitter.com/PinscreenInc'}>
                            <IconButton light>
                                <TwitterIcon/>
                            </IconButton>
                        </Link>
                    </li>
                </StyledSocialsList>
            </StyledFooterDiv>
        </StyledExpanderDiv>
    )
}