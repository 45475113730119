import styled from "styled-components";
import React, {useState} from 'react'

const size = {
    mobile: '768px',
    tablet: '1280px',
    laptop: '1600px',
    desktopLarge: '2560px'
  }

  export const device = {
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    desktopLarge: `(min-width: ${size.desktop})`
  };