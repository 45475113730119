

import styled from "styled-components";
import MinimalVideo from "./MinimalVideo";

export const ImageContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`

export const ContentOverContainer = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const Contents = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export default function ContentOverVideo(props){

    return (
    <ImageContainer>
        <MinimalVideo borderRadius={props.borderRadius} looping={true} playing={props.playing != null ? props.playing : true} source={props.source} previewImage={props.previewImage} aspectRatio={props.aspectRatio} maxHeightLimit={props.maxHeightLimit} videoBrightness={props.videoBrightness}/>
        <ContentOverContainer>
            <Contents>
                {props.children}
            </Contents>
        </ContentOverContainer>
    </ImageContainer >
  );
}
