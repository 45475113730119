import liHao from '../images/team/Hao.jpg'
import chenFrances from '../images/team/Frances.jpg'
import weiCosimo from '../images/team/Cosimo.jpg'
import agarwalAviral from '../images/team/Avi.jpg'
import arthurCaleb from '../images/team/Caleb.jpg'
import goldwhiteLain from '../images/team/Lain.jpg'
import huLiwen from '../images/team/Liwen.jpg'
import kuangLok from '../images/team/Lok.jpg'
import kungHanwei from '../images/team/Hanwei.jpg'
import luoHuiwen from '../images/team/Huiwen.jpg'
import liZimo from '../images/team/Zimo.jpg'
import wangZejian from '../images/team/Immo.jpg'
import xuQingguo from '../images/team/Qingguo.jpg'
import dengAnda from '../images/team/Anda.jpg'
import rodriguezDavid from '../images/team/David.jpg'
import whiteBrandon from '../images/team/Brandon.jpg'
import GeierSerge from '../images/team/Serge.jpg'
import SpielburgAndy from '../images/team/Andy.jpg'
import leeKatherine from '../images/team/Katherine.jpg'
import dinhTan from '../images/team/Tan.jpg'
import breglerMax from '../images/team/Max.jpg'
import huXinying from '../images/team/Xinying.jpg'
import deavillaFernando from '../images/team/Fernando.jpg'
import awerbuckDaniel from '../images/team/Daniel.jpg'
import sallesLuc from '../images/team/Luc.jpg' 




export const TeamMembers = [
  {
    name: 'Hao Li, PhD',
    title: 'Co-founder, CEO',
    website: '//hao-li.com',
    profilePic: liHao,
  },
  {
    name: 'Frances Chen',
    title: 'Co-founder, CFO',
    website: '',
    profilePic: chenFrances,
  },
  {
    name: 'Cosimo Wei, PhD',
    title: 'CTO',
    website: 'http://cosimo.cn/',
    profilePic: weiCosimo,
  },
  {
    name: 'Liwen Hu, PhD',
    title: 'VP of R&D',
    website: 'http://www-scf.usc.edu/~liwenhu/',
    profilePic: huLiwen,
  },
  {
    name: 'Zimo Li, PhD',
    title: 'Principal Scientist',
    website: '',
    profilePic: liZimo,
  },
  {
    name: 'Serge Geier',
    title: 'Business Development',
    website: '',
    profilePic: GeierSerge,
  },
  {
    name: 'Lain Goldwhite',
    title: 'Senior Software Engineer',
    website: '//heysnapshere.com',
    profilePic: goldwhiteLain,
  },
  {
    name: 'Zejian Wang',
    title: 'Senior Software Engineer',
    website: '',
    profilePic: wangZejian,
  },
  {
    name: 'Tan Minh Dinh',
    title: 'Research Scientist',
    website: '',
    profilePic: dinhTan,
  },
  {
    name: 'Aviral Agarwal',
    title: 'Senior Technical Artist',
    website: '//avi.artstation.com',
    profilePic: agarwalAviral,
  },
  {
    name: 'Brandon White',
    title: 'Animator',
    website: 'https://www.linkedin.com/in/brandonwhite000/',
    profilePic: whiteBrandon,
  },
  {
    name: 'Fernando De Avila',
    title: 'Digital Artist',
    website: '',
    profilePic: deavillaFernando,
  },
  {
    name: 'Luc Salles',
    title: 'Digital Artist',
    website: 'https://www.linkedin.com/in/luc-salles-47555916a',
    profilePic: sallesLuc,
  }
];