import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { YouTubePlayer } from '../general/GeneralStyles'

const FullPageContainer = styled.button`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${props =>  props.isOpen ? props.theme.panelTransparentDark : '#00000000'};
    backdrop-filter: ${props => props.isOpen ? 'blur(5px)' : 'blur(0px)'};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: ${props => props.isOpen ? props.isOpen : 'none'};
    z-index: 10000000000000000;
    padding: 10vh 10vw 10vh 10vw;

    transition-property: backdrop-filter, background;
    transition-duration: 0ms.4;
    transition-timing-function: ease;
`

const YoutubeContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: fill;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100000000000000000;
`

const CloseButton = styled.button`
    top: calc(86px + 1rem);
    right: 1rem;
    position: fixed;
    color: ${props => props.theme.textLight};
    font-family: 'Courier New', Courier, monospace;
    border-radius: 1rem;
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: scale(1);
    background: none;

    :hover{
        transform: scale(1.2);
    }
    transition: transform ease 0.4s;
`

export default function YoutubePopup(props) {

    return (
        <FullPageContainer isOpen={props.isOpen} onClick={props.onClose}>
            <CloseButton onClick={props.onClose}>
            ✖
            </CloseButton>
            {
                props.isOpen ?
            <YoutubeContainer>
                <YouTubePlayer aspectRatio={'16/9'} src={props.youtubeLink}/>
            </YoutubeContainer>
            :
            <>
            </>
            }
        </FullPageContainer>
    );
}
