import styled from "styled-components";
import React, { useEffect, useRef, useState} from 'react'
import 'swiper/css/bundle';

export const AudioContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
`

export const Waveform = styled.svg.attrs( props => ({
    style: {
        animationName: props.animationName,
        animationDelay:  props.delay + 's'
    }
}))`
    opacity: 0;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
`

export const PlayButton = styled.button`
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.textLight};
    width: 2rem;
    height: 2rem;
    font-family: 'swiper-icons';
    font-variant: initial;
    font-size: 1rem;
    border-radius: 50%;
    background: ${props => props.theme.panelTransparent};
    :hover {
        background: ${props => props.theme.panelTransparentLight};
    }
`

export const AudioHidden = styled.audio`
    display: none;
    width: 0;
    height: 0;
`

export default function AudioWavePlayer(props) {
    const [playing, setPlaying] = useState(false);
    const [audioContext, setAudioContext] = useState(false);
    const audioElement = useRef();

    const playAudio = () => {
        setAudioContext(new AudioContext());
        audioElement.play();
    }
    // const playAudio = () => {
    //     setAudioContext(new AudioContext());
    //     let fileReader = new FileReader();
    //     fileReader.onload = function (ev) {
    //       audioContext.decodeAudioData(ev.target.result).then(function (buffer) {
    //         playSound(buffer);
    //       });
    //     };
    //     // setAudio( new Audio(props.source) );
    //     // audio.play();
    //   };
    return(
        <AudioContainer>
            {
                playing ?
                <></>
                :
                <PlayButton onClick={ () => { playAudio(); } }>
                    next
                </PlayButton>
            }
            <AudioHidden ref={audioElement} src={props.source}/>
        </AudioContainer>
    )
}