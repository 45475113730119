import React from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";
import { device } from '../models/device';

export const StyleSlide = styled(SwiperSlide)`
    position: relative;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    background: none;
    border: none;
`

export const StyledContentDivTop = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.textLight};
    height: 100%;
    width: 100%;
`

export default function PressSlide(props) {
    return (
            <StyledContentDivTop>
                {props.children}
            </StyledContentDivTop>
    );
}
