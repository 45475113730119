import React, {useState, useEffect} from 'react'
import { RegularContentDiv, ContentSection, StyledPageDiv, StyledSpacerDiv } from '../general/GeneralStyles'
import styled from 'styled-components'
import PressSlider from '../components/PressSlider'
import { PressLinks } from '../models/press-links'
import PressSlide from '../components/PressSlide'
import { SwiperSlide } from 'swiper/react'
import { theme } from '../models/theme'
import PillButton from '../components/PillButton'
import TintContent from '../components/TintContent'
import { Helmet } from 'react-helmet-async'

const PressContainer = styled.div`
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex start;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
`

export const PressPanel = styled.div`
position: relative;
    border-radius: ${props => props.theme.borderRadius};
    aspect-ratio: ${props => props.aspectRatio ? props.aspectRatio : 'unset'};
    height: calc((50vh - 43px - 52px - 4.5rem));
    object-fit: cover;
    width: ${props => props.setWidth};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    text-align: left;
`

const TextRegion = styled.div`
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    text-align: left;
    
    width: 100%;
    height: auto;
    
    backdrop-filter: blur(1rem) brightness(80%);
    margin-bottom: 1rem;
    padding-left: 1rem;

    p, h1, h2 {
        padding-left: 1rem;
        margin-block: 0;
        z-index: 10;
    }
`

const PressLink = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
    top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    background: ${props => props.backgroundImg ? "url(\""+props.backgroundImg+"\") no-repeat center" : props.theme.panelTransparent};
    filter: saturate(0.5);
    background-size: cover;
    border-radius: ${props => props.theme.borderRadius};
    text-align: left;
    padding: 1rem;
    position: absolute;
    &::after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: ${props => props.tintColor};
        mix-blend-mode: soft-light;
        border-radius: ${props => props.theme.borderRadius};
        transition: all ease 0.5s;
    }


    :hover {
        transform: scale(1.02);
        transition: all ease 0.5s;
    }
`

const PressQuote = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    background: ${props => props.theme.panelTransparent};
    background-size: cover;
    border-radius: ${props => props.theme.borderRadius};
    text-align: left;
    padding: 1rem;
    p, h1, h2 {
        margin-block: 0;
        font-weight: 400;
        font-weight: ${props => props.isHovered ? '800' : '400' };
        /* transform: translateZ(0) scale(${props => props.isHovered ? '1.02' : '1' }); */
    }
`

export default function AboutUsPress() {

    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    useEffect(() => {
        // for resize handling
        function handleResize() {
            setDimensions({
              height: window.innerHeight ,
              width: window.innerWidth 
            });
        }

        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);
    const [numPressPages, setNumPressPages] = useState(1)
    const pressWidths = [['60%', '40%'], ['25%', '25%'], ['25%', '25%']]
    const [pressHoverMap, setPressHoverMap] = useState([])
    let ti = -1
    return(
        <StyledPageDiv>
                <Helmet>
                    <title>Press</title>
                    <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                    <meta property="og:title" content="Press" />
                    <meta
                    property="og:description"
                    content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                    />
                </Helmet>
            <StyledSpacerDiv/>
            <RegularContentDiv>
                <ContentSection>
                    <h1>
                        In the News
                    </h1>
                    <PressContainer>
                        {
                            PressLinks.map( (year) => {
                                return year.links.map( (pressItem) => {
                                    ti++
                                    if(ti < numPressPages * pressWidths.length) {
                                    return <>
                                    <PressPanel setWidth={dimensions.width <= 768 ? '100%' : 'calc('+(pressWidths[(ti%pressWidths.length)])[0]+' - 1rem)'}>
                                        <PressLink onMouseEnter={() => {
                                                let newHoverMap = [...pressHoverMap]
                                                newHoverMap[pressItem.link] = true
                                                setPressHoverMap(newHoverMap)     
                                            }} onMouseLeave={() => {
                                                let newHoverMap = [...pressHoverMap]
                                                newHoverMap[pressItem.link] = false
                                                setPressHoverMap(newHoverMap)                                            
                                                }} href={pressItem.link} target='_blank' backgroundImg={pressItem.image} tintColor='rgba(25, 125, 80, 0.3)'>
                                        </PressLink>
                                        <TextRegion>
                                            <h1>{pressItem.publication}</h1>
                                            <h2>{pressItem.mmdd + ' ' + year.year}</h2>
                                        </TextRegion>
                                    </PressPanel>
                                    <PressPanel setWidth={dimensions.width <= 768 ? '100%' : 'calc('+(pressWidths[(ti%pressWidths.length)])[1]+' - 1rem)'}>
                                        <PressQuote href={pressItem.link} target='_blank' isHovered={pressHoverMap[pressItem.link] ? true : null}>
                                            { pressItem.description ? <p>{"\""+pressItem.description+"\""}</p> : <></>}
                                        </PressQuote>
                                    </PressPanel>
                                    </>
                                    } else {
                                        return <></>
                                    }
                            })})
                        }
                    </PressContainer>
                    <PillButton baseBackground={theme.button} baseColor={theme.textLight} hoverBackground={theme.buttonHover} hoverColor={theme.textLight} value={"See More"} handleClick={() => { setNumPressPages(numPressPages+1) }}/>
                </ContentSection>
            </RegularContentDiv>
        </StyledPageDiv>
    )
}