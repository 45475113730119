

import styled from "styled-components";

 const TintContainer = styled.div`
    display: flex;
    position: relative;
    width: auto;
    height: auto;

    &::after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: ${props => props.tintColor};
        mix-blend-mode: soft-light;
    }
`

export default function TintContent(props){

    return (
    <TintContainer tintColor={props.tintColor}>
        {props.children}
    </TintContainer >
  );
}
