import React, {useState} from 'react'
import { RegularContentDiv, ContentSection, StyledPageDiv, StyledSpacerDiv } from '../general/GeneralStyles'
import { Helmet } from 'react-helmet-async'
export default function Error() {
    return(
        <StyledPageDiv>
            <Helmet>
                <title>Error</title>
                <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
                <meta property="og:title" content="Error" />
                <meta
                property="og:description"
                content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
                />
            </Helmet>            <StyledSpacerDiv/>
            <RegularContentDiv>
                <ContentSection>
                    <p>Something went wrong. <a href="/">Reload</a></p>
                </ContentSection>
            </RegularContentDiv>
        </StyledPageDiv>
    )
}