import React from 'react'
import styled from "styled-components";
import { Swiper } from "swiper/react";
import { device } from '../models/device';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import 'swiper/css/effect-fade';

import { Mousewheel, Autoplay, Pagination, Parallax, Navigation, FreeMode, Keyboard, Grid, EffectFade } from "swiper";

const FadeSwiper = styled(Swiper)`
    width: 100%;
    height: ${props => props.setHeight ? props.setHeight : 'auto'};
    display: flex;
    justify-content: center;
    align-items: center;
    --swiper-pagination-color: #000;
    --swiper-theme-color: #000;
    --swiper-pagination-bullet-inactive-color: #999;
    --swiper-pagination-bullet-inactive-opacity: 1.0;

    .swiper-button-prev, .swiper-button-next {
      color: ${props => props.theme.panelTransparentLight};
      :hover {
          color: ${props => props.theme.textLight};
      }

      @media ${device.mobile} {
          transform: scale(0.5);
      }

      transition: all ease 0.5s;
    }
`


export default function PressSlider(props){

    return(
    <FadeSwiper 
    modules={[Mousewheel, Parallax, Autoplay, FreeMode, Navigation, Keyboard, Grid]} 
    setHeight = {props.setHeight}
    direction={"horizontal"}
    autoplay={props.autoplayOn ? {
        delay: 3000,
        disableOnInteraction: false,
      } : undefined} 
    keyboard={{
        enabled: true,
      }}
      grid={{
        fill: 'column',
        rows: 3,
      }}
    navigation={true}
    onSlideChange={ (swiper) => {props.onSlideChange && typeof props.onSlideChange === 'function' && props.onSlideChange(swiper) }}
    slidesPerView={3} 
    spaceBetween={16} 
    freeMode={{sticky:true, momentum:true}} 
    loop={true}>
        {props.children}
    </FadeSwiper>
  )
    };