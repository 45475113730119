import React, {useState} from 'react'
import styled from "styled-components";
import {useSpring, animated} from 'react-spring'
import useMeasure from 'react-use-measure'
import {BrowserRouter as Router, Link} from 'react-router-dom';

import { device } from '../models/device';

const StyledButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    font-size: 1.1rem;
    white-space: nowrap;
    color: ${props => props.theme.textLight};
    background: none;
    text-align: center;
    cursor: pointer;

    :hover {
        color: ${props => props.theme.accent};
        background: none;
    }
    @media ${device.tablet} {
        padding: 0;
    }

    @media ${device.mobile} {
        width: 100%;
        border-radius: 0;
        background: ${props => props.theme.panelDark};
        :hover, :active {
            color: ${props => props.theme.accent};
            background: ${props => props.theme.panelDark};
        }   
    }
`;

const StyledList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: auto;
    border-radius: ${props => props.theme.borderRadius};
    background:  ${props => props.theme.panelTransparent};
    @media ${device.mobile} {
        background: ${props => props.theme.panelDark};
        padding: 0;
        border-radius: 0;
        width: 100%;
    }
    
    gap: 12px;

    @media ${device.mobile} {
        gap: 0;
    }

    :first-child {
        padding-top: 0.5rem;
    }

    :last-child {
        padding-bottom: 0.5rem;
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: auto;
        a {
            color: ${props => props.theme.textLight};
            text-align: center;
            width: auto;
        }

        @media ${device.mobile} {
            margin: 0px;
            width: 100%;
        }
    }
`;

const StyledDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    @media ${device.mobile} {
        flex-direction: column;
    }
`;

const StyledNav = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
    @media ${device.mobile} {
        width: 100%;
        height: auto;
    }
`;

const StyledListButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    text-align: center;
    padding: 6px 0 6px 0;
    font-size: 1rem;
    font-weight: 100;
    white-space: nowrap;
    color: ${props => props.theme.textLight};
    background: none;
    cursor: pointer;
    :hover {
        color: ${props => props.theme.accent};
        background: none;       
    }
    @media ${device.mobile} {
        border-radius: 0;
        background: ${props => props.theme.panelDark};
        :hover {
            color: ${props => props.theme.accent};
            background: ${props => props.theme.panelDark};
        }
    }
`;

const StyledAnimatedDiv = styled.div`
        height: ${props => (props.showList || props.isMobile) && props.listBounds && props.listBounds.height  ? (props.listBounds.height + 'px') : '0px' };
        opacity: ${props => (props.showList || props.isMobile) ? '1' : '0' };
        width:  148px;
        overflow: hidden;
        position: absolute;
        top: calc(${props => (props.buttonBounds.bottom - 43 + 'px')} + 1.1rem + 0.55rem + 1.1rem);
        left: calc(50% - 74px);
        @media ${device.mobile} {
            left: unset;
            top:unset;
            position: relative;
            width: 100%;
            padding-top: 0;
        }
        transition: all ease .18s;
`;

export default function NavbarList({title, contents, onClickedSubMenu}) {
    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })
    const [showList, setShowList] = useState(false);
    const [isMobile, setIsMobile] = useState(dimensions.width <= 786);
    const [listRef, listBounds] = useMeasure()
    const [buttonRef, buttonBounds] = useMeasure()

    React.useEffect(() => {
        function handleResize() {
            console.log('resized to: ', window.innerWidth , 'x', window.innerHeight)
            setDimensions({
              height: window.innerHeight,
              width: window.innerWidth
            });

            if(dimensions.width <= 786){
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }


        window.addEventListener('resize', handleResize)

        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const listItems = contents.map((item) =>
        <div>
            <Link onClick={ () => {if(onClickedSubMenu) onClickedSubMenu()} } to={item[1]}>
                <StyledListButton>{item[0]}</StyledListButton>
            </Link>
        </div>
    );

    function enter () {
        if(!isMobile){
            setShowList(true);
        }
    }

    function leave () {
        if(!isMobile){
            setShowList(false);
        }
    }

    return(
        <StyledNav>
            <StyledDiv onMouseLeave={leave}>
                <Link onClick={ () => {if(onClickedSubMenu) onClickedSubMenu()} } to={contents[0][1]}>
                    <StyledButton onMouseEnter={enter} ref={buttonRef}>{title}</StyledButton>
                </Link>
                <StyledAnimatedDiv showList={showList} isMobile={isMobile} buttonBounds={buttonBounds} listBounds={listBounds}>
                    <StyledList ref={listRef}>
                        {listItems}
                    </StyledList>
                </StyledAnimatedDiv>
            </StyledDiv>
        </StyledNav>
    )
}