import React, { useState, useEffect } from "react";
import { useLayoutEffect } from "react";
import styled from "styled-components";
import { theme } from '../models/theme'

const SVG = styled.svg`
    pointer-events: none;

    position: absolute;
    padding:0;
    margin:0;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    /* temp for debug */
    /* z-index: 10000; */
    stroke-dasharray: 4 10;
    stroke-linecap: round;
    fill: none;

    @keyframes dash-anim {
    from {stroke-dashoffset: 0;}
    to {stroke-dashoffset: 14;}
    }

    animation-name: ${props => props.animated ? 'dash-anim' : '' };
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: ${props => props.reversedAnim ? 'reverse' : 'normal'};
`

const PATH = styled.path`
    transition: stroke ease 2s;
    stroke-width: 4;
`

// export default function ConnectorSVG(props) {
//     const [xa, setXA] = useState(0);
//     const [ya, setYA] = useState(0);
//     const [xb, setXB] = useState(0);
//     const [yb, setYB] = useState(0);

//     useLayoutEffect(() => {
//         console.log("got bounds A");
//         setXA(props.boundsA.right);
//         setYA((props.boundsA.top - props.boundsA.bottom) / 2.0);
//     }, [props.boundsA]);

//     useLayoutEffect(() => {
//         setXB(props.boundsB.right);
//         setYB((props.boundsB.top - props.boundsB.bottom) / 2.0);
//     }, [props.boundsB]);

//     return (
//         <SVG xa={xa} ya={ya} xb={xb} yb={xb} viewBox={`0 0 --app-width --app-height`}>
//             <path d={`M  ${(xa - xb) / 2.0}, ${ya} 
//             C ${xa}, ${ya} ${(xa - xb) / 2.0}, ${ya} ${(xa - xb) / 2.0}, ${(ya - yb) / 2.0} 
//             S ${(xa - xb) / 2.0}, ${(ya - yb) / 2.0} ${xb}, ${yb}`} stroke-width="4" stroke="white"/>
//         </SVG>
//     );
// }

export default function ConnectorSVG(props) {
    const [elementP, setElementP] = useState(null);
    const [elementA, setElementA] = useState(null);
    const [elementB, setElementB] = useState(null);

    const [d, setD] = useState("");
    const [rectP, setRectP] = useState({
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
    });
    // const [cx, setCX] = useState("");
    // const [cy, setCY] = useState("");

    useEffect(() => {
        if(props.elementP){
            setElementP(document.getElementById(props.elementP));
        }
    }, [props.elementP]);

    useEffect(() => {
        if(props.elementA){
            setElementA(document.getElementById(props.elementA));
            // console.log(`got element from id ${props.elementA} as ${elementA}`);
        }
    }, [props.elementA]);

    useEffect(() => {
        if(props.elementB){
            setElementB(document.getElementById(props.elementB));
        }
    }, [props.elementB]);

    useEffect(() => {
        // for resize handling
        function handleResize() {
            let xa = 0;
            let ya = 0;
            let xb = 0;
            let yb = 0;
            let rectPT = null;

            if(elementP != null){
                rectPT = elementP.getBoundingClientRect();
                // console.log(rectPT);
                if(elementA != null){
                    const rectA = elementA.getBoundingClientRect();
                    // console.log(rectA);
                    xa = rectA.right - rectPT.x;
                    ya = (rectA.y + (rectA.height / 2.0)) - rectPT.y;
                }
    
                if(elementB != null){
                    const rectB = elementB.getBoundingClientRect();
                    // console.log(rectB);
                    xb = rectB.left - rectPT.x;
                    yb = (rectB.y + (rectB.height / 2.0)) - rectPT.y;
                }
                // console.log(`xa: ${xa} ya: ${ya} xb: ${xb} yb: ${yb}`)
            }

            let cpx1 = (xa + xb) / 2.0;
            let cpy1 = (ya + yb) / 2.0;

            let cpx2 = (xa - xb) / 2.0;
            let cpy2 = (ya - yb) / 2.0;

            setD(`M  ${xa}, ${ya} 
            C ${xa}, ${ya} ${cpx1}, ${ya} ${cpx1}, ${cpy1}
            S ${xb}, ${yb} ${xb}, ${yb}`);

            if(rectPT != null){
                setRectP(rectPT);
            }

            // setD(`M  ${xa}, ${ya} 
            // L ${xa}, ${ya} ${xb}, ${yb}`);

            // setCX(cpx1);
            // setCY(ya);

            // console.log(`new d: ${d}`);
        }
        

        window.addEventListener('resize', handleResize);
        handleResize();

        const intervalId = setInterval(() => {
            handleResize();
          }, 1000);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearInterval(intervalId);
        }
    }, [elementA, elementB]);

    return (
        <SVG viewBox={`0 0 ${rectP.width} ${rectP.height}`} reversedAnim={props.reversedAnim} animated={props.animated}>
            <PATH d={d} stroke={props.stroke ? props.stroke : theme.textLight}/>
            {/* <circle class="EndPoint" cx={cx} cy={cy} r="4" fill="red" /> */}
        </SVG>
    );
}
