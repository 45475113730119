import placeholder from '../images/landing/placeholder_faceswap.png'

export const OpenJobs = [
  {
    title: 'VFX Supervisor',
    skills: 'CG & AI Technologies, Cinematography/Post-Production, Leadership (Nuke, Maya/Arnold, Adobe Suite, Major Film Credits)'
  },
  {
    title: 'VFX Producer',
    skills: 'CGI, Cinematography/Post-Production, Leadership/Organization/Planning (Nuke, Maya, Adobe Suite, Major Film Credits)'
  },
  {
    title: 'VFX Artist',
    skills: 'Tracking, Rotoscoping, Lighting, Compositing (Nuke, Maya, Adobe Suite, Maya/Arnold, Unreal/Unity)'
  },
  {
    title: 'Software Engineer',
    skills: 'Graphics Programming, Game Development, Tools/SDK Development (Python, C/C++, C#, HLSL/GLSL, Unreal/Unity)'
  },
  {
    title: 'Research Scientist',
    skills: 'Generative AI, Diffusion Models, GANs, Image/Video/Speech Generation (Python, C/C++, First-Authored Papers)'
  },
  {
    title: 'Research Intern',
    skills: 'Generative AI, Diffusion Models, GANs, Image/Video/Speech Generation (Python, C/C++)'
  },
];