import React, { useState, useEffect, createContext, Fragment } from 'react'

import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from './globalStyles'

import Navbar from './components/Navbar'

import Home from './pages/Home'
import AboutUsCompany from './pages/About-Us-Company'
import AboutUsPress from './pages/About-Us-Press'
import EthicsSafety from './pages/Ethics-Safety';
import AvatarCreatorApp from './pages/Avatar-Neo-Creator-App'
import AvatarDeveloper from './pages/Avatar-Neo-Developer'
import Contact from './pages/Contact'
import Jobs from './pages/Jobs'
import VFXProjects from './pages/VFX-Projects'
import VFXProjectsDetails from './pages/VFX-Projects-Details'
import VFXServices from './pages/VFX-Services'
import VFXTechnology from './pages/VFX-Technology'
import VFXResearch from './pages/VFX-Research';
import TermsOfUse from './pages/Terms-Of-Use';
import PrivacyPolicy from './pages/Privacy-Policy';
import Error from './pages/Error';

import Footer from './components/Footer'

import {BrowserRouter as Router, Routes, Route, Outlet, createBrowserRouter, createRoutesFromElements, RouterProvider, ScrollRestoration} from 'react-router-dom'
import { StatusCodes } from "http-status-codes";
import './App.css'

import Terms from './content/legal/terms.txt'
import Privacy from './content/legal/privacy.txt'

import { theme } from './models/theme'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const StyledBody = styled.div`
    z-index: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(var(--app-height) - 96px);
    max-width: 100vw;
`;


function App() {
  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");

  useEffect(() => {
    fetch(Terms)
      .then((res) => res.text())
      .then((txt) => setTerms(txt));
  }, []);

  useEffect(() => {
    fetch(Privacy)
      .then((res) => res.text())
      .then((txt) => setPrivacy(txt));
  }, []);

  const appHeight = () => {
    const doc = document.documentElement
    const barHeight = window.screen.height - window.screen.availHeight;
    //console.log("screenheight"+window.screen.height+"screenavilheight"+window.screen.availHeight+"innerhight"+window.innerHeight);
    var ua = window.navigator.userAgent;
    if(ua.match(/iPad/i) || ua.match(/iPhone/i) || ua.match(/Android|webOS|iPhone|iPod|Blackberry/i)){
      doc.style.setProperty('--app-height', `${window.innerHeight - barHeight}px`)
    }else{
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
  }

  const appWidth = () => {
    const doc = document.documentElement
    const barWidth = window.screen.width - window.screen.availWidth;
    //console.log("screenheight"+window.screen.height+"screenavilheight"+window.screen.availHeight+"innerhight"+window.innerHeight);
    var ua = window.navigator.userAgent;
    if(ua.match(/iPad/i) || ua.match(/iPhone/i) || ua.match(/Android|webOS|iPhone|iPod|Blackberry/i)){
      doc.style.setProperty('--app-width', `${window.innerWidth - barWidth}px`)
    }else{
      doc.style.setProperty('--app-width', `${window.innerWidth}px`)
    }
  }
  
  window.addEventListener('resize', appHeight);
  appHeight();

  window.addEventListener('resize', appWidth);
  appWidth();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<><Navbar/><StyledBody><Outlet/></StyledBody><Footer/><ScrollRestoration/></> } errorElement={<><Navbar/><StyledBody><Error/></StyledBody><Footer/><ScrollRestoration/></>} >
          <Route path='' element={<Home/>} />
          <Route path='about-us-company' element={<AboutUsCompany/>} />
          <Route path='about-us-press' element={<AboutUsPress/>} />
          <Route path='ethics-safety' element={<EthicsSafety/>}/>
          <Route path='avatar-neo-creator-app' element={<AvatarCreatorApp/>} />
          <Route path='avatar-neo-developer-sdk' element={<AvatarDeveloper/>} />
          <Route path='contact' element={<Contact/>} />
          <Route path='jobs' element={<Jobs/>} />
          <Route path='vfx-projects' element={<VFXProjects/>} />
          <Route path='vfx-projects/details/:projectName' element={<VFXProjectsDetails/>} />
          <Route path='vfx-services' element={<VFXServices/>} />
          <Route path='vfx-technology' element={<VFXTechnology/>} />
          <Route path='vfx-research' element={<VFXResearch/>} />
          <Route path='terms-of-use' element={<TermsOfUse value={terms}/>} />
          <Route path='privacy-policy' element={<PrivacyPolicy value={privacy}/>} />
        </Route> 
      </>
    )
  );

  return (
    <HelmetProvider>
      <Helmet>
          {/* Global site tag (gtag.js) - Google Analytics  */}
          {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-DY41F12BPF"></script>
          <script> {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-DY41F12BPF');
            `}
          </script> */}
          <title>Pinscreen</title>
          <meta name="description" content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)." key="desc" />
          <meta property="og:title" content="Pinscreen" />
          <meta
          property="og:description"
          content="Pinscreen is an American technology company and VFX studio that develops the most advanced generative AI technologies for film production and interactive experiences with its headquarter in Los Angeles and branch in Abu Dhabi (UAE)."
          />
          <meta property="og:image" content='https://www.pinscreen.com/images/websiteMetaDataPreview.jpg'/>
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle/>
        <RouterProvider router={router} />
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App
