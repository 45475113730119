import React from 'react'
import styled from "styled-components";
import { Swiper } from "swiper/react";
import { device } from '../models/device';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

import { Mousewheel, Autoplay, Pagination, Parallax, Navigation, FreeMode, Keyboard } from "swiper";

const CarouselSwiper = styled(Swiper)`
    width: 100%;
    height: ${props => props.setHeight ? props.setHeight : 'auto'};
    --swiper-pagination-color: #000;
    --swiper-theme-color: #000;
    --swiper-pagination-bullet-inactive-color: #999;
    --swiper-pagination-bullet-inactive-opacity: 1.0;

    .swiper-button-prev, .swiper-button-next {
        color: ${props => props.theme.panelTransparentLight};
        padding: 0 24px 0 24px;
        :hover {
            color: ${props => props.theme.textLight};
        }

        @media ${device.mobile} {
            padding: 0 12px 0 12px;
            transform: scale(0.5);
        }

        transition: all ease 0.5s;
    }

    .swiper-pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        bottom: 20px;
        height: 0;
    }

    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: ${props => props.theme.primary};
        opacity: 1;
        width: 0.5rem;
        height: 0.5rem;
    }

    .swiper-pagination-bullet {
        margin: 8px !important;
        width: 0.5rem;
        height: 0.5rem;
        background-color: ${props => props.theme.panelTransparentLight};
        border-radius: 100px;
        /* transform: skewX(-20deg); */
        opacity: 1;
        transition: all ease 0.5s;

        :hover {
            background-color: ${props => props.theme.primary};
            opacity: 1;
        }
    }
`

const PagenationTick = styled.div`
    width: 4rem;
    border-radius: 0;
`


export default function HorizontalPreviewSlider(props){
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"/>';
        },
        };

    return(
    <CarouselSwiper
    cssMode={false}
    onSwiper={props.swiperInit}
    modules={[Mousewheel, Parallax, Autoplay, FreeMode, Navigation, Pagination, Keyboard]} 
    setHeight = {props.setHeight}
    direction={"horizontal"}
    autoplay={props.autoplayOn ? {
        delay: 10000,
        disableOnInteraction: false,
      } : undefined} 
    keyboard={{
        enabled: true,
      }}
    onSlideChange={ (swiper) => {props.onSlideChange && typeof props.onSlideChange === 'function' && props.onSlideChange(swiper) }}
    navigation={true}
    slidesPerView={1} 
    spaceBetween={0} 
    freeMode={{sticky:true, momentum:true}} 
    pagination={{clickable: true, }} 
    loop={true}>
        {props.children}
    </CarouselSwiper>
  )
    };