import styled, { css } from "styled-components";
import React, {useState, useEffect} from 'react'
import { device } from '../models/device';
import { useSpring, animated } from 'react-spring';

export const StyledButtonInputBase = css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${props => props.setWidth ? '100%' : 'auto'};
    height: auto;
    text-align: center;
    margin: 0;
    padding: 12px;
    border: none;
    border-radius: 64px;
    border-width: 4px;
    font-size: 1.25rem;
    font-weight: 100;
    white-space: nowrap;
    backdrop-filter: blur(5px);
    cursor: pointer;
    @media ${device.mobile} {
        //width: 128px;
    }
    
    transition: all ease 0.2s;
`;

export const StyledButton = styled.input`
    ${StyledButtonInputBase}
    background: ${ props => props.baseBackground };
    color: ${ props => props.baseColor };
    :hover {
        background: ${ props => props.hoverBackground };
        color: ${ props => props.hoverColor };
    }
`;

export const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: ${props => props.setWidth ? '100%' : 'auto'};
    height: auto;
`;

function buttonContent(props, setIsHovered) {
    return(
        <StyledButton type="button" baseBackground={props.baseBackground} baseColor={props.baseColor} hoverBackground={props.hoverBackground} hoverColor={props.hoverColor} value={props.value} onChange={props.handleChange} onClick={props.handleClick} onMouseEnter={ () => {setIsHovered(true);}} onMouseLeave={ () => {setIsHovered(false);}} setWidth={props.setWidth}>
            {props.children}
        </StyledButton>
    );
}

export default function PillButton(props) {
    const [isHovered, setIsHovered] = React.useState(false);
    const hoverAnimStyle = useSpring({
        width: props.setWidth ? props.setWidth : 'auto',
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        config: { mass: 1, tension: 500, friction: 80 }
        });
    useEffect(() => {
        // button animation on hover using react spring
        
      }, []);
    return(
        <animated.div style={hoverAnimStyle} setWidth={props.setWidth}>
            <StyledLabel setWidth={props.setWidth}>
                {buttonContent(props, setIsHovered)}
            </StyledLabel>
        </animated.div>
    )
}